import { MapModel, MapType } from "../models/map.model";
import { StatusCodes } from "http-status-codes";
import { BaseService } from "./base.service";
import { ReturnPagination } from "../models/return.model";
import { PaginationModel } from "../models/pagination.model";
import { EnterpriseModel, EnterpriseType } from "../models/enterprise.model";

export default class EnterpriseService extends BaseService {
  constructor() {
    super();
  }

  createEnterprise= async (values: EnterpriseType) => {
    if (!(values instanceof EnterpriseModel)) {
      throw new TypeError(
        'The Enterprise must be an instance of class "MapType"'
      );
    }

    return await this.axios
      .post(`${this.apiUrl}/api/enterprise`, values, {
        headers: this.getHeader(),
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.CREATED].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return { data: error.message, success: false };
      });
  };

  updateEnterprise = async (values: EnterpriseType) => {
    if (!(values instanceof EnterpriseModel)) {
      throw new TypeError(
        'The Enterprise must be an instance of class MapType'
      );
    }

    return await this.axios
      .put(`${this.apiUrl}/api/enterprise/`, values, {
        headers: this.getHeader(),
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.NO_CONTENT].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return { data: error.message, success: false };
      });
  };

  deleteEnterprise = async (name: string) => {
    return await this.axios
      .delete(`${this.apiUrl}/api/enterprise/`, {
        headers: this.getHeader(),
        data: {
          name: name,
        },
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.NO_CONTENT].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response.status +
            " : " +
            error.response.data?.error,
          success: false,
        };
      });
  };

  getEnterprises = async (
    limit: number,
    page: number = 1,
    filter: any = {}
  ) => {
    return await this.axios
      .get(`${this.apiUrl}/api/enterprises`, {
        headers: this.getHeader(),
        params: {
          page: page,
          limit: limit,
          filter: filter
        },
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if (
          [
            StatusCodes.CREATED,
            StatusCodes.NO_CONTENT,
            StatusCodes.ACCEPTED,
            StatusCodes.OK,
          ].includes(res.status)
        ) {
          const returnGet = res.data as ReturnPagination;
          const pagination = returnGet.data as PaginationModel;
          return {
            data: pagination,
            success: true,
          };
        }
       
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response?.status +
            " : " +
            error.response?.data?.error,
          success: false,
          redirect: this.checkError(error.response?.status),
        };
      });
  };


  getEnterprise = async (name: string) => {
    return await this.axios
      .get(`${this.apiUrl}/api/enterprise/`, {
        headers: this.getHeader(),
        params: {
          name: name,
        },
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if (
          [
            StatusCodes.CREATED,
            StatusCodes.NO_CONTENT,
            StatusCodes.ACCEPTED,
            StatusCodes.OK,
          ].includes(res.status)
        ) {
          return {
            data: res.data.data,
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response?.status +
            " : " +
            error.response?.data?.error,
          success: false,
          redirect: this.checkError(error.response?.status),
        };
      });
  };
}
