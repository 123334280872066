import { createSlice } from "@reduxjs/toolkit";

const subscriptionsModel = {
  expiringSubs: [],
  alert: 0,
};

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: subscriptionsModel,
  reducers: {
    updateExpiringSubs: (state, action) => {
      return { ...state, expiringSubs: action.payload.expiringSubs, alert: action.payload.alert };
    }
  },
});

export const {
  updateExpiringSubs,
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
