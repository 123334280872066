import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  type Row,
} from "@tanstack/react-table";
import { useState } from "react";
import {
  PencilIcon,
  TrashIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  RocketLaunchIcon,
  CalendarDaysIcon,
  EyeIcon,
  EyeSlashIcon,
  DocumentDuplicateIcon,
  BellAlertIcon
} from "@heroicons/react/24/outline";
import { TertiaryButton, TertiaryLink } from "../buttons/buttons";
import "./table.scss";
import { RoundedCheckbox } from "../checkbox/checkbox";

interface TablePropsType {
  columns: ColumnDef<any>[];
  data: any[];
  cellStyle?: string;
  tableStyle?: string;
  actions?: actionsType | null;
  disabledActions?: disabledActionsType;
  renderSubComponent?: (props: { row: Row<any> }) => React.ReactElement
  getRowCanExpand?: (row: Row<any>) => boolean
}
export interface actionsType {
  duplicate?: (row: any) => void;
  update?: (row: any) => void;
  delete?: (row: any) => void;
  select?: (row: any) => void;
  visit?: (row: any) => void;
  checkSub?: (row: any) => void;
  checkAff?: (row: any, nm: any) => void;
  notified?: (row: any) => void;
  updateTooltip?: string;
  deleteTooltip?: string;
  selectTooltip?: string;
  visitTooltip?: string;
  checkSubTooltip?: string;
  checkAffTooltip?: string;
}
export interface disabledActionsType {
  duplicate?: (row: any) => boolean;
  update?: (row: any) => boolean;
  delete?: (row: any) => boolean;
  select?: (row: any) => boolean;
  visit?: (row: any) => boolean;
  checkSub?: (row: any) => boolean;
}
export default function Table({
  columns,
  data,
  cellStyle,
  tableStyle,
  actions,
  disabledActions,
  renderSubComponent,
  getRowCanExpand
}: TablePropsType) {
  const [sorting, setSorting] = useState<SortingState>([]);

  // Track visibility per row using an object
  const [visibility, setVisibility] = useState<Record<number, boolean>>({});

  // Toggle function
  const toggleVisibility = (rowIndex: number, rowData: any) => {
    setVisibility((prev:any) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));

    // Call the action if it exists
    actions?.checkAff?.(rowData, rowIndex);
  };

  const table = useReactTable({
    columns,
    data,
    debugTable: true,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      sorting,
    },
  });
  return (
    <table className={tableStyle}>
      <thead className="border-b-2 border-zinc-500">
        {table.getHeaderGroups().map((headerGroup, index) => (
          <tr key={headerGroup.id + 'key' + index}>
            {headerGroup.headers.map((header) => {
              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={cellStyle}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={
                        (header.column.getCanSort()
                          ? "cursor-pointer select-none"
                          : "") + " text-zinc-700 text-md font-semibold"
                      }
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Croissant"
                            : header.column.getNextSortingOrder() === "desc"
                              ? "Décroissant"
                              : "Rétablir"
                          : undefined
                      }
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <div>
                        {(header.column.getIsSorted() as string) === "asc" ? (
                          <ChevronUpIcon className="w-5 h-5" />
                        ) : (header.column.getIsSorted() as string) ===
                          "desc" ? (
                          <ChevronDownIcon className="w-5 h-5" />
                        ) : null}
                      </div>
                    </div>
                  )}
                </th>
              );
            })}
            {actions && <th className="text-zinc-700 text-md font-semibold">Actions</th>}
          </tr>
        ))}
      </thead>
      <tbody>
        {table
          .getRowModel()
          .rows.slice(0.1)
          .map((row, index) => {
            return (
              <>
                <tr
                  key={index}
                  className={
                    row.original.style +
                    (row.original.collectionMother ? " text-indigo-700" : "") +
                    " border-b border-zinc-300" + (row.original.disabled ? " disabled" : "")
                  }
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className={cellStyle} id={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                  <td>
                    <div className={(actions && Object.keys(actions).length > 1 ? "gap-x-2" : "") + " flex justify-center items-center"}>
                      {actions?.update && (
                        <TertiaryButton
                          textClassname="rounded-full p-2 bg-white"
                          disabled={disabledActions?.update?.(row) ?? false}
                          onClick={() =>
                            actions.update ? actions.update(row.original) : {}
                          }
                          icon={<PencilIcon className="h-4 w-4" />}
                        />
                      )}
                      {actions?.duplicate && (
                        <TertiaryButton
                          textClassname="rounded-full p-2 bg-white"
                          disabled={disabledActions?.duplicate?.(row) ?? false}
                          onClick={() =>
                            actions.duplicate ? actions.duplicate(row.original) : {}
                          }
                          icon={<DocumentDuplicateIcon className="h-4 w-4" />}
                        />
                      )}
                      {actions?.delete && (
                        <TertiaryButton
                          textClassname="rounded-full p-2 bg-white"
                          disabled={disabledActions?.delete?.(row) ?? false}
                          onClick={() =>
                            actions.delete ? actions.delete(row.original) : {}
                          }
                          icon={<TrashIcon className="h-4 w-4" />}
                        />
                      )}
                      {actions?.visit && (
                        <TertiaryButton
                          textClassname="rounded-full p-2 bg-white"
                          onClick={() =>
                            actions.visit ? actions.visit(row.original) : {}
                          }
                          icon={<RocketLaunchIcon className="h-4 w-4" />}
                        />
                      )}
                      {actions?.checkSub && (
                        <TertiaryButton
                          textClassname="rounded-full p-2 bg-white"
                          onClick={() =>
                            actions.checkSub ? actions.checkSub(row.original) : {}
                          }
                          icon={<CalendarDaysIcon className="h-4 w-4" />}
                          tooltip={actions.checkSubTooltip || "Vérifier les subscription"}
                        />
                      )}
                      {actions?.checkAff && (
                        <TertiaryButton
                          textClassname="rounded-full p-2 bg-white"
                          onClick={() => toggleVisibility(index, row.original)}
                          tooltip={actions.checkAffTooltip || "Vérifier les affectations"}
                          icon={
                            visibility[index] ? (
                              <EyeSlashIcon className="h-4 w-4" />
                            ) : (
                              <EyeIcon className="h-4 w-4" />
                            )
                          }
                        />
                      )}
                      {actions?.notified && (
                        <RoundedCheckbox
                          defaultChecked={row.original.notified}
                          tooltip={row.original.notified ? "Ignoré par " + row.original.user + " le " + row.original.updatedDate : "Notifié"}
                          icon={<BellAlertIcon className="w-4 h-4 text-zinc-800"/>}
                          onChange={() => actions.notified ? actions.notified(row.original) : {}}
                        />
                       
                      )}
                    </div>
                  </td>
                </tr>
                {renderSubComponent && row.getIsExpanded() && (
                  <>{renderSubComponent({ row })}</>
                )}
              </>
            );
          })}
      </tbody>
    </table>
  );
}
