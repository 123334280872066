import { useEffect, useMemo, useState } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import {
  PrimaryButton,
  PrimaryLink,
  TertiaryButton,
} from "../../components/buttons/buttons";
import SearchBar, { InputType } from "../../components/searchBar/searchBar";
import { userType } from "../../models/user.model";
import UsersService from "../../services/users.service";
import Table from "../../components/table/table";
import { ColumnDef } from "@tanstack/react-table";
import toastUtil from "../../utils/toast.util";
import { ToastType } from "../../parts/toast/toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { closeToast } from "../../store/toast.slice";
import ReactPaginate from "react-paginate";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import "../../components/pagination/paginate.scss";
import { PaginationModel } from "../../models/pagination.model";
import DotsSpinner from "../../components/dotsSpinner/dotsSpinner";

interface Props {}

function Users({}: Props) {
  const [searching, setSearching] = useState<boolean>(false);
  const [reload, setReaload] = useState<boolean>(true);
  const [textFilter, setTextFilter] = useState<string>("");
  const usersService = new UsersService();

  const [userPagination, setPagination] = useState<PaginationModel>({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const limit: number = 10;

  const usersSearchInputs: InputType[] = [
    { name: "", type: "text", onChange: setTextFilter },
  ];
  const [users, setUsers] = useState<userType[]>([]);

  const search = (page: number) => {
    setSearching(true);
    const filtered = textFilter || undefined;
    usersService.getUsers(limit, page, filtered).then((res) => {
      if (!res.success) {
        const toastData: ToastType = {
          title: "Erreur de chargement",
          texts: [{ text: res.data }],
          status: "error",
          position: "bottomRight",
          autoClose: false,
        };

        if (res.redirect !== null) {
          return navigate(res.redirect, { state: { error: res.data } });
        }
        return toastUtil.show(toastData, dispatch);
      }
      if (res.data?.docs) {
        setUsers(res.data.docs);
        setPagination(res.data);
        setReaload(false);
      }
      setSearching(false);
    });
  };

  const handlePageClick = (event: { selected: number }) => {
    search(event.selected + 1);
  };

  const filter = () => {
    search(0);
  };

  useEffect(() => {
    if (reload) search(0);
  }, [reload]);

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "lastName",
        header: "Nom",
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "firstName",
        header: "Prénom",
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "email",
        header: "Email",
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "role",
        header: "Rôle",
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "enterprise",
        header: "Entreprise",
        sortingFn: "alphanumeric",
      },
    ],
    []
  );

  const updateCollection = (user: userType) => {
    navigate("/newUser", { state: { update: user } });
  };

  const validateDeletion = (user: userType) => {
    const toastData: ToastType = {
      title: "Suppression",
      texts: [
        {
          text:
            "Voulez-vous supprimer l'utilisateur " +
            user.firstName +
            " " +
            user.lastName +
            " ?",
        },
      ],
      status: "neutre",
      autoClose: false,
      blocking: true,
      position: "middle",
      buttons: [
        <TertiaryButton
          key="no"
          label="Non"
          onClick={() => dispatch(closeToast())}
          textClassname="text-sm px-6 py-2"
        />,
        <div onClick={() => dispatch(closeToast())}>
          <PrimaryButton
            key="yes"
            label="Oui"
            onClick={() => deleteCollection(user.email)}
            textClassname="text-sm"
          />
        </div>,
      ],
    };
    toastUtil.show(toastData, dispatch);
  };

  const deleteCollection = (email: string = "") => {
    usersService.deleteUser(email).then((res: any) => {
      const toastData: ToastType = {
        title: res.success ? "Utilisateur supprimé" : "Erreur",
        texts: [{ text: res.success ? "" : res.data }],
        status: res.success ? "success" : "error",
        position: "bottomRight",
        autoClose: res.success,
      };
      toastUtil.show(toastData, dispatch);

      setReaload(true);
    });
  };

  const actions = {
    update: updateCollection,
    delete: validateDeletion,
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center">
        <h2 className="title">Liste des utilisateurs</h2>
        <PrimaryLink
          label="Ajouter un utilisateur"
          to="/newUser"
          btnClassname="addButton"
          textClassname="text-sm"
          icon={<PlusIcon className="h-5 w-5" aria-hidden="true" />}
        />
      </div>
      <SearchBar inputs={usersSearchInputs} action={filter} />
      {!searching && users.length < 1 && 
        <div className="mt-8 p-3 mx-auto text-md">
          Aucun résultat
        </div>
      }
      {searching && <DotsSpinner className="centered"/>}
      {users.length > 0 && 
      <>
        <Table
          columns={columns}
          data={users}
          actions={actions}
          cellStyle="min-w-32"
        />
        <ReactPaginate
          nextLabel={
            <span className="w-10 h-10 flex items-center justify-center rounded bg-gray-200 hover:bg-gray-100">
              <ChevronRightIcon className="w-5 h-5" />
            </span>
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          breakLabel="..."
          pageCount={userPagination.totalPages || 0}
          previousLabel={
            <span className="w-10 h-10 flex items-center justify-center rounded bg-gray-200 mr-4 hover:bg-gray-100">
              <ChevronLeftIcon className="w-5 h-5" />
            </span>
          }
          renderOnZeroPageCount={null}
          containerClassName="flex items-center justify-center mt-8 mb-4"
          pageClassName="btnPage block border border-solid border-gray-200 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded mr-4"
          breakClassName="mr-4"
          activeClassName="bg-indigo-600 text-white hover:bg-indigo-600"
        />
      </>}
    </div>
  );
}

export default Users;
