import React, { useEffect, useMemo, useState } from "react";
import {
  PrimaryButton,
  PrimaryLink,
  TertiaryButton,
} from "../../components/buttons/buttons";
import { PlusIcon } from "@heroicons/react/24/outline";
import SearchBar, { InputType } from "../../components/searchBar/searchBar";
import EnterpriseService from "../../services/enterprises.service";
import Table from "../../components/table/table";
import { ColumnDef, Row } from "@tanstack/react-table";
import { ToastType } from "../../parts/toast/toast";
import { useDispatch } from "react-redux";
import toastUtil from "../../utils/toast.util";
import { closeToast } from "../../store/toast.slice";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import "../../components/pagination/paginate.scss";
import "./enterprises.scss";
import { PaginationModel } from "../../models/pagination.model";
import ReactPaginate from "react-paginate";
import DotsSpinner from "../../components/dotsSpinner/dotsSpinner";
import { EnterpriseType } from "../../models/enterprise.model";

const enterpriseService = new EnterpriseService();

const Enterprises = () => {
  const [searching, setSearching] = useState<boolean>(false);
  const [reload, setReaload] = useState<boolean>(true);
  const [enterprises, setEnterprises] = useState<EnterpriseType[]>([]);
  const [textFilter, setTextFilter] = useState<string>("");
  const [actions, setActions] = useState<any>({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const limit: number = 10;

  const [enterprisePagination, setPagination] = useState<PaginationModel>({});

  const user = localStorage.getItem("currentUser");

  const enterpriseSearchInputs: InputType[] = [
    { name: "Nom", type: "text", onChange: setTextFilter },
  ];


  useEffect(() => {

    setActions({
      update: updateEnterprise,
      delete: validateDeletion
    })
  }, []);


  const search = (page: number) => {
    setSearching(true);
    const filtered = textFilter || undefined;
    enterpriseService.getEnterprises(limit, page, filtered).then((res) => {
      if (!res.success) {
        const toastData: ToastType = {
          title: "Erreur de chargement",
          texts: [{ text: res.data }],
          status: "error",
          position: "bottomRight",
          autoClose: false,
        };

        if (res.redirect !== null) {
          return navigate(res.redirect, { state: { error: res.data } });
        }
        return toastUtil.show(toastData, dispatch);
      }
      if (res.data?.docs) {
        console.log(res.data.docs);
        setEnterprises(res.data.docs);
        setPagination(res.data);
        setReaload(false);
      }
      setSearching(false);
    });
  };

  const handlePageClick = (event: { selected: number }) => {
    search(event.selected + 1);
  };

  const filter = () => {
    search(0);
  };

  useEffect(() => {
    if (reload)
      search(0);
  }, [reload]);

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "users",
        header: "Users",
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "description",
        header: "Description",
        sortingFn: "alphanumeric",
      }
    ],
    []
  );

  const updateEnterprise = (enterprise: EnterpriseType) => {
    navigate("/newEnterprise", { state: { update: enterprise } });
  };


  const validateDeletion = (enterprise: EnterpriseType) => {
    let buttons =[<TertiaryButton
      key="no"
      label="Non"
      onClick={() => dispatch(closeToast())}
      textClassname="text-sm px-6 py-2"
    />
    ]
    const text = "Voulez-vous supprimer l'enterprise " + enterprise.name + " ?";
       buttons.push(
        <div key="yes" onClick={() => dispatch(closeToast())}>
          <PrimaryButton
            label="Oui"
            onClick={() => deleteEnterprise(enterprise.name)}
            textClassname="text-sm"
          />
        </div>
       )
    

    const toastData: ToastType = {
      title: "Suppression",
      texts: [
        {
          text:
            text,
        },
      ],
      status: "neutre",
      autoClose: false,
      blocking: true,
      position: "middle",
      buttons: buttons,
    };
    toastUtil.show(toastData, dispatch);
  };

  const deleteEnterprise = (name: string) => {
    enterpriseService.deleteEnterprise(name).then((res: any) => {
      const toastData: ToastType = {
        title: res.success ? "Map supprimée" : "Erreur",
        texts: [{ text: res.success ? "" : res.data }],
        status: res.success ? "success" : "error",
        position: "bottomRight",
        autoClose: res.success,
      };
      toastUtil.show(toastData, dispatch);

      setReaload(true);
    });
  };


  return (
    <div className="flex flex-col maps">
      <div className="flex justify-between items-center">
        <h2 className="title">Liste des enterprises</h2>
        <div className="buttonsBlock">
          <PrimaryLink
            label="Ajouter une enterprise"
            to="/newEnterprise"
            btnClassname="addButton"
            textClassname="text-sm"
            icon={<PlusIcon className="h-5 w-5" aria-hidden="true" />}
          />
        </div>
      </div>
      <SearchBar inputs={enterpriseSearchInputs} action={filter} />
      {searching && <DotsSpinner className="centered" />}
      {!searching && enterprises.length < 1 &&
        <div className="mt-8 p-3 mx-auto text-md">
          Aucun résultat
        </div>
      }
      {enterprises.length > 0 &&
        <>
          <Table
            columns={columns}
            data={enterprises}
            actions={actions}
            cellStyle="min-w-32"
          />

          <ReactPaginate
            nextLabel={
              <span className="w-10 h-10 flex items-center justify-center rounded bg-gray-200 hover:bg-gray-100">
                <ChevronRightIcon className="w-5 h-5" />
              </span>
            }
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            breakLabel="..."
            pageCount={enterprisePagination.totalPages || 0}
            previousLabel={
              <span className="w-10 h-10 flex items-center justify-center rounded bg-gray-200 mr-4 hover:bg-gray-100">
                <ChevronLeftIcon className="w-5 h-5" />
              </span>
            }
            renderOnZeroPageCount={null}
            containerClassName="flex items-center justify-center mt-8 mb-4"
            pageClassName="btnPage block border border-solid border-gray-200 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded mr-4"
            breakClassName="mr-4"
            activeClassName="bg-indigo-600 text-white hover:bg-indigo-600"
          />
        </>
      }
    </div>
  );
};

export default Enterprises;
