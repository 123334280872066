import { Outlet } from "react-router";
import "./App.css";
import Navbar from "./parts/navbar/navbar";
import Toast from "./parts/toast/toast";
import useDeviceDetection from "./hook/useDeviceDetection";
import Footer from "./parts/footer/footer";
import DotsSpinner from "./components/dotsSpinner/dotsSpinner";
import { useSelector } from "react-redux";

function App() {
  const userDevice = useDeviceDetection();
  const waiting = useSelector((state: any) => state.spinner.containerSpinner);

  return (
    <main className={userDevice}>
      <Navbar />
      <section className="masterSection bg-zinc-50">
        <Outlet/>
        
       {waiting &&  
          <>
            <div className="blur centered"></div>
            <DotsSpinner className="centered"/>
          </>
        }
        <Footer />
      </section>
      <Toast />
    </main>
  );
}

export default App;
