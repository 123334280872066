import { useNavigate, useLoaderData } from "react-router-dom";
import Maps from "./maps/maps";
import Subscriptions from "./subscriptions/subscriptions";
import { useEffect } from "react";
import { USER_ROLE } from "../constants";

export const DynamicHome: React.FC = () => {
    const userRole = useLoaderData();
    const navigate = useNavigate();

    useEffect(() => {
        if (userRole === USER_ROLE.VIEWER) {
           return navigate("/maps");
        } else {
            return navigate("/searchSubscriptions?mode=search");
        }
    }, [userRole, navigate]); // update url for navbar active item

    return userRole !== USER_ROLE.VIEWER ? <Subscriptions /> : <Maps />;
};
