import React, { forwardRef, useState, useEffect, useCallback } from "react";
import "./maps.scss";
import { PrimaryButton, SecondaryLink } from "../../components/buttons/buttons";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import InputText from "../../components/inputText/inputText";
import {ItemType} from "../../components/select/select";
import { MapModel, MapType } from "../../models/map.model";
import MapService from "../../services/maps.service";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastType } from "../../parts/toast/toast";
import toastUtil from "../../utils/toast.util";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

import Checkbox from "../../components/checkbox/checkbox";
import EnterpriseService from "../../services/enterprises.service";

interface Props { }

const NewMap = (props: Props) => {
  const [title, setTitle] = useState<string>("Nouvelle Map");
  const [name, setName] = useState<string>("");
  const [enterprises, setEnterprises] = useState<string[]>([]);
  const [type, setType] = useState<string>("weekly/monthly");
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [maps, setMaps] = useState<MapType[]>([]);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const MapsService = new MapService();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const limit: number = 10000;
  const enterpriseService = new EnterpriseService();

  const [enterprisesArray, setEnterprisesArray] = useState<ItemType[]>([]);
 

  useEffect(() => {
    if (location.state?.update) {
      setIsUpdate(true);
      const MapToUpdate: MapType = location.state.update;
      setName(MapToUpdate.name);
      setEnterprises(MapToUpdate.enterprises);
      setType(MapToUpdate.type);
      setTitle("Modification de la Map");
    }
    
    setEnterprisesTable();
  }, [location.state]);


  const setEnterprisesTable = () => {
    // Fetch enterprise names from the backend
    const filtered = undefined;
    enterpriseService.getEnterprises(limit, 0, filtered).then((res) => {
      if (!res.success) {
        const toastData: ToastType = {
          title: "Erreur de chargement",
          texts: [{ text: res.data }],
          status: "error",
          position: "bottomRight",
          autoClose: false,
        };

        if (res.redirect !== null) {
          return navigate(res.redirect, { state: { error: res.data } });
        }
        return toastUtil.show(toastData, dispatch);
      }
      if (res.data?.docs) {
        console.log(res.data.docs);
        setEnterprisesArray(res.data.docs);
      }
    });
  }

  useEffect(() => {
    setIsCompleted(enterprises.length > 0 && name !== null && type !== "");
  }, [enterprises, name, type]);

  const feedback = (res: { success: boolean; data: any }) => {
    const toastData: ToastType = {
      title: res.success ? "Map enregistrée avec succès" : "Erreur",
      texts: [{ text: res.success ? "" : res.data }],
      status: res.success ? "success" : "error",
      position: "bottomRight",
      autoClose: res.success,
    };
    toastUtil.show(toastData, dispatch);
    if (res.success) navigate("/maps");
  };

  const onSave = () => {

    const MapValues: MapType = {
      name,
      enterprises,
      type
    };

    const colModel = new MapModel(MapValues);

    MapsService[isUpdate ? "updateMap" : "createMap"](
      colModel
    ).then((res) => feedback(res));
  };

  const updateSelection = useCallback((enterprise: string) => {
    setEnterprises((prevEnterprises:string[]) => 
        prevEnterprises.includes(enterprise)
            ? prevEnterprises.filter((item:string) => item !== enterprise) // Remove if exists
            : [...prevEnterprises, enterprise] // Add if not exists
    );
}, [setEnterprises]);



  return (
    <div className="flex flex-col">
      <header className="flex justify-between items-center pr-5">
        <h2 className="title">{title}</h2>
        <SecondaryLink
          label="Annuler"
          to="/maps"
          btnClassname="backButton"
          textClassname="text-sm"
          icon={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
        />
      </header>
      <form className="flex flex-col w-full max-w-xl gap-y-4 rounded-lg bg-white mb-6 p-6 mx-auto shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
        <small className="text-indigo-700">* Tous les champs sont requis</small>
        <InputText
          type="text"
          label="name"
          id="name"
          classname="w-full"
          value={name}
          onChange={(e: any) => setName(e.target.value)}
        />
        <div className="w-full flex gap-4 justify-center items-center">
          <div className="font-medium leading-6 text-gray-900 mb-0">
          Selectionnez les enterprises :
            <ul
              role="list"
              className="max-h-96 overflow-y-auto niceScroll mx-6 divide-y divide-blue-200 "
            >
              {enterprisesArray.map((enterprise: ItemType) => {
                return (
                  <li
                    className={
                      "w-full flex justify-between py-2 px-2 cursor-pointer " +
                      (enterprises.indexOf(enterprise.name)  > -1
                        ? "bg-indigo-50"
                        : "") +
                      " hover:bg-indigo-50"
                    }
                    key={enterprise.id}
                  >
                    {enterprise.name}

                    <Checkbox
                      id={enterprise.id}
                      label=""
                      onChange={() => updateSelection(enterprise.name)}
                      checked={enterprises.indexOf(enterprise.name) > -1}
                    />

                  </li>
                );
              })}
            </ul>
          </div>
          <InputText
            type="text"
            label="Type"
            id="type"
            placeholder="weekly/monthly"
            classname="w-1/2"
            disabled={true}
            value={type}
            onChange={(e: any) => setType(e.target.value)}
          />
        </div>
        <PrimaryButton
          label="Enregistrer"
          onClick={onSave}
          textClassname="text-md mx-auto"
          btnClassname="mt-8"
          disabled={!isCompleted}
        />
      </form>
    </div>
  );
};

export default NewMap;
