import "./searchBar.scss";
import useDeviceDetection from "../../hook/useDeviceDetection";
import Select from "../select/select";
import InputText from "../inputText/inputText";
import { SecondaryButton } from "../buttons/buttons";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import Checkbox from "../checkbox/checkbox";

export interface InputType {
  name: string;
  type: string;
  value?: string;
  resettable?: boolean;
  onReset?: () => void;
  onChange: (e: any) => void;
  onClick?: (e: any) => void;
  data?: any[];
}
export interface SearchBarPropsType {
  inputs: InputType[];
  action?: () => void;
  isDisabled?: boolean;
}

export default function SearchBar({
  inputs,
  action,
  isDisabled,
}: SearchBarPropsType) {
  const userDevice = useDeviceDetection();

  function inputTypeSwitcher(input: InputType, act:any =action): any {
    switch (input.type) {
      case "text":
        return (
          <InputText
            key={input.name}
            label={input.name}
            type="text"
            value={input?.value}
            onClick={input?.onClick}
            id={input.name}
            classname="text-zinc-900"
            onChange={(e: any) => input.onChange(e.target.value)}
            onReset={input?.onReset}
            onKeyDown={(e: any) => {
              if (e.key === "Enter" && act) {
                act(); // Call a custom function if provided
              }
            }}
          ></InputText>
        );
      case "select":
        if (input?.data && input?.data?.length > 0) {
          return (
            <Select
              key={input.name}
              label={input?.name}
              items={input?.data}
              classname="text-zinc-900"
              onChange={input?.onChange}
            ></Select>
          );
        }
        break;
      case "check":
        return (
          <Checkbox
            key={input.name}
            id={input.name}
            label={input.name}
            classname="font-medium text-zinc-900"
            onChange={input.onChange}
          ></Checkbox>
        );
    }
  }

  return (
    <div className="searchBar bg-zinc-100">
      {inputs.map((input: InputType) => inputTypeSwitcher(input))}

      {action && <SecondaryButton
        label="Rechercher"
        onClick={action}
        textClassname="text-sm"
        icon={<MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />}
        disabled={isDisabled}
      />}
    </div>
  );
}
