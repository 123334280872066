import {
  SubscriptionModel,
  subscriptionType,
} from "../models/subsrcription.model";
import { StatusCodes } from "http-status-codes";
import { BaseService } from "./base.service";

/// 0019395A (mères) | 9999999P (fille)
export default class ExpiringSubscriptionsService extends BaseService {
  constructor() {
    super();
  }

  deleteSubscription = async (idAbonnement: string) => {
    return await this.axios
      .delete(`${this.apiUrl}/api/subscription/`, {
        headers: this.getHeader(),
        data: {
          idAbonnement: idAbonnement,
        },
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.NO_CONTENT].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response.status +
            " : " +
            error.response.data?.error,

          details: error.response.data?.data,
          success: false,
        };
      });
  };

  purgeExpiredSubscriptions = async () => {
    return await this.axios
      .delete(`${this.apiUrl}/api/expiringSubscriptions/`, {
        headers: this.getHeader(),
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.NO_CONTENT].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response.status +
            " : " +
            error.response.data?.error,

          details: error.response.data?.data,
          success: false,
        };
      });
  };

  getExpiredSubscriptions = async (
    limit: number,
    page: number = 1,
    filter: any = {}
  ) => {
      return await this.axios
        .get(`${this.apiUrl}/api/expiredSubscriptions/`, {
          headers: this.getHeader(),
          params: {
            page: page,
            limit: limit,
            filter: filter
          },
        })
        .then(async (res: { status: StatusCodes; data: any }) => {
          if (StatusCodes.OK === res.status) {
            return { success: true, data: res?.data.data };
          }
          return { success: false, data: res?.data.error };
        })
        .catch((error: any) => {
          return {
            data:
              "Code " +
              error.response?.status +
              " : " +
              error.response.data?.error,
            success: false,
            redirect: this.checkError(error.response.status),
          };
        });
    
  };

  getExpiringSubscriptions = async () => {
      return await this.axios
        .get(`${this.apiUrl}/api/expiringSubscriptions/`, {
          headers: this.getHeader(),
        })
        .then(async (res: { status: StatusCodes; data: any }) => {
          if (StatusCodes.OK === res.status) {
            return { success: true, data: res?.data.data };
          }
          return { success: false, data: res?.data.error };
        })
        .catch((error: any) => {
          return {
            data:
              "Code " +
              error.response?.status +
              " : " +
              error.response.data?.error,
            success: false,
            redirect: this.checkError(error.response.status),
          };
        });
    
  };

  createExpiringSubscriptions = async (expSubscriptions: Array<subscriptionType>) => {
    return await this.axios
      .post(
        `${this.apiUrl}/api/expiringSubscriptions/`,
        JSON.stringify(expSubscriptions),
        {
          headers: this.getHeader(),
        }
      )
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.NO_CONTENT].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response.status +
            " : " +
            error.response.data?.error,
          details: error.response.data?.data,
          success: false,
        };
      });
  };

  updateExpiringSubscription = async (values: subscriptionType, notified: boolean = false) => {

    const sub = {...values};
    if(notified) sub.notified = !values.notified;

    return await this.axios
      .put(
        `${this.apiUrl}/api/expiringSubscription/`, sub,
        {
          headers: this.getHeader(),
        }
      )
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.NO_CONTENT].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response.status +
            " : " +
            error.response.data?.error,
          details: error.response.data?.data,
          success: false,
        };
      });
  };
}
