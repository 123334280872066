import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TERipple } from "tw-elements-react";
import DotsSpinner from "../dotsSpinner/dotsSpinner";
import "./buttons.scss";

export interface buttonType {
  label?: string;
  to?: string;
  icon?: any;
  disabled?: boolean;
  btnClassname?: string;
  textClassname?: string;
  target?: string;
  tooltip?: string;
  onClick?: () => {} | void | null;
}

export function PrimaryButton({
  label,
  btnClassname = "",
  textClassname = "",
  disabled = false,
  icon,
  tooltip,
  onClick,
}: buttonType) {
  const btnSpinner = useSelector((state: any) => state.spinner.btnSpinner);
  return (
    <TERipple rippleColor="light" className={btnClassname}>
      <button
        className={
          textClassname +
          " flex items-center gap-1 inline-block rounded px-6 py-2 font-medium leading-normal bg-yellow-300 text-zinc-800 shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out enabled:hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] enabled:focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] enabled:focus:outline-none enabled:focus:ring-0 enabled:active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] disabled:opacity-75"
        }
        type="button"
        onClick={onClick}
        disabled={disabled || btnSpinner}
        aria-label={label || "Button"}
        {...(tooltip ? { title: tooltip } : {})} 
      >
        {icon}
        {label}
        {btnSpinner && <DotsSpinner className="right-8" />}
      </button>
    </TERipple>
  );
}

export function SecondaryButton({
  label,
  btnClassname = "",
  textClassname = "",
  disabled = false,
  icon,
  tooltip,
  onClick,
}: buttonType) {
  return (
    <TERipple rippleColor="light" className={btnClassname}>
      <button
        type="button"
        className={
          "flex items-center gap-1 inline-block rounded px-6 py-2 font-medium h-full leading-normal bg-zinc-700 text-white transition duration-150 ease-in-out hover:bg-zinc-800 focus:bg-zinc-700 active:bg-zinc-700 disabled:opacity-75 disabled:hover:bg-zinc-600 " +
          textClassname
        }
        onClick={onClick}
        disabled={disabled}
        aria-label={label || "Button"}
        {...(tooltip ? { title: tooltip } : {})} 
      >
        {icon}
        {label}
      </button>
    </TERipple>
  );
}

export function TertiaryButton({
  label,
  btnClassname = "",
  textClassname = "",
  disabled = false,
  icon,
  tooltip,
  onClick,
}: buttonType) {
  return (
    <TERipple rippleColor="light" className={btnClassname}>
      <button
        type="button"
        className={
          "flex items-center gap-1 inline-block rounded font-medium leading-normal h-full border-2 border-gray-300 text-zinc-800 transition duration-150 ease-in-out enabled:hover:border-yellow-300 focus:border-yellow-300 active:border-yellow-300 disabled:opacity-60 disabled:bg-zinc-100 " +
          textClassname
        }
        onClick={onClick}
        disabled={disabled}
        aria-label={label || "Button"}
        {...(tooltip ? { title: tooltip } : {})} // Only adds title if tooltip exists
      >
        {icon}
        {label}
      </button>
    </TERipple>
  );
}

export function PrimaryLink({
  label,
  to = "",
  btnClassname = "",
  textClassname = "",
  icon,
}: buttonType) {
  return (
    <Link className={btnClassname} to={to} aria-label={label || "Link"}>
      <span
        className={
          "flex items-center gap-1 rounded px-6 py-2 text-center font-medium leading-normal bg-yellow-300 text-zinc-800 shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] disabled:opacity-75 " +
          textClassname
        }
      >
        {icon}
        {label}
      </span>
    </Link>
  );
}
export function SecondaryLink({
  label,
  to = "",
  btnClassname = "",
  textClassname = "",
  icon,
  target,
}: buttonType) {
  return (
    <Link className={btnClassname} to={to} target={target} aria-label={label || "Link"}>
      <span
        className={
          "flex items-center gap-1 rounded px-6 py-2 text-center font-medium leading-normal bg-gray-200 text-zinc-800 transition duration-150 ease-in-out hover:bg-gray-300 focus:bg-gray-300 active:bg-gray-300 " +
          textClassname
        }
      >
        {icon}
        {label}
      </span>
    </Link>
  );
}

export function TertiaryLink({
  label,
  to = "",
  btnClassname = "",
  textClassname = "",
  icon,
}: buttonType) {
  return (
    <Link className={btnClassname} to={to} aria-label={label || "Link"}>
      <span
        className={
          "flex items-center gap-1 inline-block font-medium leading-normal border-2 border-gray-300 text-zinc-800 transition duration-150 ease-in-out hover:border-yellow-300 focus:border-yellow-300 active:border-yellow-300 " +
          textClassname
        }
      >
        {icon}
        {label}
      </span>
    </Link>
  );
}

export function ToggleButtons( { buttons }: { buttons: buttonType[] }) {
  return (
    <div className="togglebuttons flex justify-center mb-4">
      {buttons.map((button: buttonType, i) => {
        return (

          <TERipple rippleColor="light" className={button.btnClassname} key={'btnToggle' + i}>
            <button
              className={
                button.textClassname +
                " flex items-center gap-1 inline-block rounded px-6 py-1 font-medium leading-normal bg-yellow-300 text-zinc-800 transition duration-150 ease-in-out enabled:focus:outline-none enabled:focus:ring-0 disabled:opacity-75"
              }
              type="button"
              onClick={button.onClick}
              disabled={button.disabled}
              aria-label={button.label || "Button"}
              {...(button.tooltip ? { title: button.tooltip } : {})} 
            >
              {button.icon && <button.icon className="h-5 w-5" aria-hidden="true" />}
              {button.label}
            </button>
          </TERipple>)
      })}
    </div>
  );

}