
import { XMarkIcon } from "@heroicons/react/20/solid";
interface inputTextType {
  label?: string;
  id?: string;
  type?: string;
  classname?: string;
  textStyle?: string;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  row?: number;
  ref?: any;
  disabled?: boolean;
  autocomplete?: string;
  onChange?: (e: any) => void;
  onClick?: (e: any) => void;
  onBlur?: (e: any) => void;
  onReset?: (e: any) => void;
  onKeyDown?: (e: any) => void;
}

export default function InputText({
  label,
  id,
  type,
  ref,
  classname,
  textStyle,
  disabled,
  defaultValue,
  autocomplete,
  value,
  placeholder,
  onChange,
  onClick,
  onBlur,
  onReset,
  onKeyDown,
}: inputTextType) {
  return (
    <div className={classname}>
      <label htmlFor={id} className="block font-medium leading-6">
        {label}
      </label>
      <div className="flex items-center">
        <input
          type={type}
          name={id}
          id={id}
          ref={ref}
          disabled={disabled}
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          autoComplete={autocomplete}
          className={(onReset ? "pr-8 " : "") + 
            textStyle + 
            " block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus-visible:outline-none sm:text-sm sm:leading-6"
          }
        />
        {onReset && <XMarkIcon className="h-5 w-5 cursor-pointer translate-x-[-1.5rem] hover:text-indigo-600" onClick={onReset}/>}
      </div>
    </div>
  );
}
export function InputTextarea({
  label,
  id,
  classname,
  row,
  onChange,
  onKeyDown,
  value,
  placeholder,
}: inputTextType) {
  return (
    <div className={classname}>
      <label htmlFor={id} className="block font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div>
        <textarea
          id={id}
          name={id}
          onChange={onChange}
          onKeyDown={onKeyDown}
          rows={row}
          value={value}
          className="block w-full px-3 py-1.5 text-sm text-gray-900 bg-gray-50 rounded-md border-0 border-gray-300 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus-visible:outline-none sm:text-sm sm:leading-6"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
