/**
 * @class Defines an user.
 */

export interface affectationType {
  UAI?: string;
  codeProjetRessource?: string;
  cumulAffectationAutrePersonnel: string;
  cumulAffectationDocumentaliste: string;
  cumulAffectationEleve: string;
  cumulAffectationEnseignant: string;
  idAbonnement?: string;
  idRessource?: string;
}

export class AffectationModel {
  uaiEtab: string | null;
  idAbonnement: string | null;
  idRessource: string | null;
  codeProjetRessource?: string;
  nbLicenceEnseignant?: Number | string;
  nbLicenceEleve?: Number | string;
  nbLicenceAutrePersonnel?: Number | string;
  nbLicenceDocumentalist?: Number | string;

  constructor(bo: affectationType = {
    cumulAffectationAutrePersonnel: '0',
    cumulAffectationDocumentaliste: '0',
    cumulAffectationEleve: '0',
    cumulAffectationEnseignant: '0'
  }) {
    this.uaiEtab = bo.UAI || null;
    this.idAbonnement = bo.idAbonnement || null;
    this.idRessource = bo.idRessource || null;
    this.nbLicenceEnseignant = bo.cumulAffectationEnseignant;
    this.nbLicenceEleve = bo.cumulAffectationEleve;
    this.nbLicenceAutrePersonnel = bo.cumulAffectationAutrePersonnel;
    this.nbLicenceDocumentalist = bo.cumulAffectationDocumentaliste;
  }
}
