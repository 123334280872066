import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthenticateService from "../../services/authenticate.service";
import "./login.scss";
import { PrimaryButton, SecondaryLink } from "../../components/buttons/buttons";
import InputText from "../../components/inputText/inputText";
import { useDispatch } from "react-redux";
import { hideBtnSpinner, showBtnSpinner } from "../../store/spinner.slice";

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [error, setError] = useState<string>("");
  const authenticateService = new AuthenticateService();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.error) {
      authenticateService.logoutAuth();
      setError(location.state.error);
    }
  }, [location.state]);

  const submit = async () => {
    setError("");
    dispatch(showBtnSpinner());
    if (email && password) {
      await authenticateService
        .loginAuth({
          email,
          password,
        })
        .then((res) => {
          dispatch(hideBtnSpinner());
          if (res?.success) {
            navigate("/");
          } else {
            setError(res.data);
          }
        });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      submit();
    }
  };

  return (
    <section className="bg-indigo-950 flex justify-center items-center h-full w-full">
      <div className="container h-fit w-full lg:w-1/2 p-8">
        <form>
          <h2 className="text-4xl py-5 text-center">
            <img
              src={`${process.env.PUBLIC_URL}/logo_Maskott.jpg`}
              alt="Logo"
              className="mx-auto"
              style={{ width: "150px" }}
            />{" "}
          </h2>
          <p className="py-4 text-center">
            {" "}
            Bienvenue sur Usages, connectez-vous
          </p>

          <InputText
            type="email"
            label="Email"
            id="email"
            classname="mb-4"
            autocomplete="email"
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <InputText
            type="password"
            label="Mot de passe"
            id="password"
            classname="mb-4"
            autocomplete="current-password"
            onChange={(e: any) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
          />

          <div className="mb-8 pb-1 pt-1 text-center flex flex-col">
            <PrimaryButton
              label="Se connecter"
              onClick={submit}
              disabled={!password || !email}
              textClassname="uppercase w-full my-3 text-md justify-center"
            />
            {error && (
              <p className="text-rose-900 rounded bg-rose-50 p-2 mb-4">
                {error}
              </p>
            )}
          </div>
          <div className="flex w-full items-center justify-center gap-x-4">
            <div>
              <p className="mb-0 mr-2">Mot de passe oublié ?</p>
              <p className="mb-0 mr-2">Vous n'avez pas de compte ?</p>
            </div>
            <SecondaryLink
              label="Faire une demande"
              to="https://maskott.atlassian.net/servicedesk/customer/portal/17"
              target="_blank"
              textClassname="text-sm"
            />
          </div>
        </form>
      </div>
    </section>
  );
};

export default Login;
