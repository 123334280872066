import { useEffect, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { PrimaryButton, SecondaryLink } from "../../components/buttons/buttons";
import Select, { ItemType } from "../../components/select/select";
import "./users.scss";
import InputText from "../../components/inputText/inputText";
import PasswordStrengthBar from "react-password-strength-bar";
import { UserModel, userType } from "../../models/user.model";
import UsersService from "../../services/users.service";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastType } from "../../parts/toast/toast";
import toastUtil from "../../utils/toast.util";
import EnterpriseService from "../../services/enterprises.service";
import { USER_ROLE } from "../../constants";

interface Props { }

const NewUser = (props: Props) => {
  const roles: ItemType[] = [
    { id: "1", name: USER_ROLE.REPORTER }, // CRUD subscriptions // R maps // notifications // affectations // collections
    { id: "2", name: USER_ROLE.SUBSCRIBER }, // CRUD subscriptions // R collections
    { id: "3", name: USER_ROLE.ADMIN }, // CRUD all
    { id: "4", name: USER_ROLE.VIEWER } // R maps
  ];
  const [title, setTitle] = useState<string>("Nouvel utilisateur");
  const [role, setRole] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordScore, setPasswordScore] = useState<number>(0);
  const [enterprise, setEnterprise] = useState<string>("");
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const usersService = new UsersService();
  const enterpriseService = new EnterpriseService();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const limit: number = 10000;

  const [enterprises, setEnterprises] = useState<ItemType[]>([]);



  useEffect(() => {
    if (location.state?.update) {
      setIsUpdate(true);
      const userToUpdate: userType = location.state.update;
      setLastName(userToUpdate.lastName);
      setFirstName(userToUpdate.firstName);
      setEmail(userToUpdate.email);
      setEnterprisesTable(userToUpdate.enterprise);
      const _role = roles.find((item) => item.name === userToUpdate.role);
      if (_role) setRole(_role.name);
      setTitle("Modification de l'utilisateur");

    } else {
      setEnterprisesTable('');
    }

  }, [location.state]);


  const setEnterprisesTable = (_enterprise: string | undefined) => {
    // Fetch enterprise names from the backend
    const filtered = undefined;
    enterpriseService.getEnterprises(limit, 0, filtered).then((res) => {
      if (!res.success) {
        const toastData: ToastType = {
          title: "Erreur de chargement",
          texts: [{ text: res.data }],
          status: "error",
          position: "bottomRight",
          autoClose: false,
        };

        if (res.redirect !== null) {
          return navigate(res.redirect, { state: { error: res.data } });
        }
        return toastUtil.show(toastData, dispatch);
      }
      if (res.data?.docs) {
        let arrayItem: ItemType[] = [];
        for (let i = 0; i < res.data.docs.length; i++) {
          arrayItem.push({ id: '' + i, name: res.data.docs[i]['name'] })
        }
        // Move the selected enterprise to the front if it exists
        if (_enterprise !== '') {
          const selectedIndex = arrayItem.findIndex((item) => item.name === _enterprise);
          if (selectedIndex !== -1) {
            const [selectedItem] = arrayItem.splice(selectedIndex, 1);
            arrayItem = [selectedItem, ...arrayItem];
          }
        }
        console.log(arrayItem);
        setEnterprises(arrayItem);
      }
    });
  }

  const feedback = (res: { success: boolean; data: any; details: string }) => {
    const toastData: ToastType = {
      title: res.success ? "Utilisateur enregistré avec succès" : "Erreur",
      texts: [{ text: res.success ? "" : res.data, details: res.details }],
      status: res.success ? "success" : "error",
      position: "bottomRight",
      autoClose: res.success,
    };
    toastUtil.show(toastData, dispatch);
    if (res.success) navigate("/users");
  };

  const onSave = () => {
    const userValues: userType = {
      firstName,
      lastName,
      role,
      password,
      email,
      enterprise,
    };

    const userModel = new UserModel(userValues);

    if (isUpdate && password === "") delete userModel.password;

    usersService[isUpdate ? "updateUser" : "createUser"](userModel).then(
      (res) => feedback(res)
    );
  };

  useEffect(() => {
    setIsCompleted(
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      enterprise !== "" &&
      (isUpdate
        ? password !== ""
          ? passwordScore >= 3
          : true
        : passwordScore >= 3)
    );
  }, [firstName, lastName, email, passwordScore, enterprise, password]);

  return (
    <div className="flex flex-col">
      <header className="flex justify-between items-center pr-5">
        <h2 className="title">{title}</h2>
        <SecondaryLink
          label="Annuler"
          to="/users"
          btnClassname="backButton"
          textClassname="text-sm"
          icon={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
        />
      </header>
      <form className="flex flex-col w-full gap-y-6 max-w-xl rounded-lg bg-white mb-6 p-6 mx-auto shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
        <small className="text-indigo-700">* Tous les champs sont requis</small>
        <div className="w-full flex gap-4 justify-center items-center">
          <InputText
            type="text"
            label="Nom"
            id="lastName"
            classname="w-1/2"
            value={lastName}
            autocomplete="given-name"
            onChange={(e: any) => setLastName(e.target.value)}
          />
          <InputText
            type="text"
            label="Prénom"
            id="firstName"
            classname="w-1/2"
            value={firstName}
            autocomplete="family-name"
            onChange={(e: any) => setFirstName(e.target.value)}
          />
        </div>
        <div className="w-full flex gap-4 justify-center items-start pt-4">
          <InputText
            type="email"
            label="Email"
            id="email"
            classname="w-1/2"
            value={email}
            autocomplete="email"
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <div className="w-1/2">
            <InputText
              type="password"
              label={isUpdate ? "Nouveau mot de passe" : "Mot de passe"}
              id="password"
              classname="w-full"
              value={password}
              autocomplete="new-password"
              onChange={(e: any) => setPassword(e.target.value)}
            />
            <PasswordStrengthBar
              password={password}
              onChangeScore={(score) => setPasswordScore(score)}
              shortScoreWord="trop court"
              scoreWords={["faible", "faible", "moyen", "bon", "fort"]}
            />
          </div>
        </div>
        <div className="w-full flex gap-4 justify-center items-center">
          {enterprises.length > 0 &&
            <Select
              label="Entreprise"
              items={enterprises}
              classname="w-1/2"
              selectedItem={enterprises[0]}
              onChange={(value: ItemType) => setEnterprise(value.name)}
            ></Select>
          }
          <Select
            label="Rôle"
            items={roles}
            classname="w-1/2"
            selectedItem={roles.find((ro) => ro.name === role)}
            onChange={(value: ItemType) => setRole(value.name)}
          ></Select>
        </div>
        <PrimaryButton
          label="Enregistrer"
          onClick={onSave}
          textClassname="text-md mx-auto"
          btnClassname="mt-8"
          disabled={!isCompleted}
        />
      </form>
    </div>
  );
};

export default NewUser;
