import { useEffect, useState } from "react";
import SearchBar, { InputType } from "../../components/searchBar/searchBar";
import Table from "../../components/table/table";
import { ToastType } from "../../parts/toast/toast";
import { useDispatch } from "react-redux";
import toastUtil from "../../utils/toast.util";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon, TrashIcon } from "@heroicons/react/24/outline";
import "../../components/pagination/paginate.scss";
import "./subscriptions.scss";
import { PaginationModel } from "../../models/pagination.model";
import ReactPaginate from "react-paginate";
import DotsSpinner from "../../components/dotsSpinner/dotsSpinner";
import ExpiringSubscriptionsService from "../../services/expiringSubscription.service";
import subscriptionUtil from "../../utils/subscription.util";
import type { subscriptionType } from "../../models/subsrcription.model";
import { PrimaryButton } from "../../components/buttons/buttons";
import { USER_ROLE } from "../../constants";


const expiringSubscitpionsService = new ExpiringSubscriptionsService();

const ExpiredSubscriptions = () => {
  const [searching, setSearching] = useState<boolean>(false);
  const [expiringSubscitpions, setExpiringSubscitpions] = useState<subscriptionType[]>([]);
  const [textFilter, setTextFilter] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [userRole, setUserRole] = useState<string>("");
  const user = localStorage.getItem("currentUser");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const limit: number = 10;

  const [expiredsubsPagination, setPagination] = useState<PaginationModel>({});

  const expiredSubscriptionsSearchInputs: InputType[] = [
    { name: "", type: "text", onChange: setTextFilter },
  ];

  const search = (page: number) => {
    setSearching(true);
    const filtered = textFilter || undefined;
    expiringSubscitpionsService.getExpiredSubscriptions(limit, page, filtered).then((res) => {
      if (!res.success) {
        const toastData: ToastType = {
          title: "Erreur de chargement",
          texts: [{ text: res.data }],
          status: "error",
          position: "bottomRight",
          autoClose: false,
        };

        if (res.redirect !== null) {
          return navigate(res.redirect, { state: { error: res.data } });
        }
        return toastUtil.show(toastData, dispatch);
      }
      
      if (res.data?.docs) {
        setExpiringSubscitpions(res.data.docs);
        setPagination(res.data);
      }

      setMessage(textFilter.length > 0 ? subscriptionUtil.messageNoSearchResultExp : subscriptionUtil.messageNoResultExp);
      
      setSearching(false);
    });
  };

  const handlePageClick = (event: { selected: number }) => {
    search(event.selected + 1);
  };

  const purge = () => {
    expiringSubscitpionsService.purgeExpiredSubscriptions().then( res => {
      const toastData: ToastType = {
        title: res.success ? "Purge réalisée avec succès" : "Erreur lors de la purge",
        texts: [{ text: res.success ? "" : res.data }],
        status: res.success ? "success" : "error",
        position: "bottomRight",
        autoClose: res.success,
      };
      
      toastUtil.show(toastData, dispatch);
      search(0);

    })
    
  };

  useEffect(() => {
    if (user) {
      setUserRole(JSON.parse(user).role);
    }

    search(0);
  }, []);

return (
    <div className="subscriptions flex flex-col">
      <div className="flex justify-between items-center">
        <h2 className="title">Abonnements expirés</h2>
         {userRole === USER_ROLE.ADMIN && <div className="buttonsBlock">
          <PrimaryButton
            label="Purge"
            onClick={purge}
            btnClassname="addButton"
            textClassname="text-sm"
            tooltip={"Supprimer les abonnements expirés depuis plus d'un mois"}
            icon={<TrashIcon className="h-5 w-5" aria-hidden="true" />}
          />
        </div>}
      </div>
      
      <SearchBar inputs={expiredSubscriptionsSearchInputs} action={() => search(0)} />
      {searching && <DotsSpinner className="centered" />}
      {!searching && expiringSubscitpions.length < 1 &&
        <div className="mt-8 p-3 mx-auto text-md">
          {message}
        </div>
      }
      {expiringSubscitpions.length > 0 &&
        <div className="mr-4 ml-4">
          <Table
            columns={subscriptionUtil.columns}
            data={expiringSubscitpions}
          />

          <ReactPaginate
            nextLabel={
              <span className="w-10 h-10 flex items-center justify-center rounded bg-gray-200 hover:bg-gray-100">
                <ChevronRightIcon className="w-5 h-5" />
              </span>
            }
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            breakLabel="..."
            pageCount={expiredsubsPagination.totalPages || 0}
            previousLabel={
              <span className="w-10 h-10 flex items-center justify-center rounded bg-gray-200 mr-4 hover:bg-gray-100">
                <ChevronLeftIcon className="w-5 h-5" />
              </span>
            }
            renderOnZeroPageCount={null}
            containerClassName="flex items-center justify-center mt-8 mb-4"
            pageClassName="btnPage block border border-solid border-gray-200 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded mr-4"
            breakClassName="mr-4"
            activeClassName="bg-indigo-600 text-white hover:bg-indigo-600"
          />
        </div>
      }
    </div>
  );
};

export default ExpiredSubscriptions;
