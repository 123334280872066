import React, { useState, useEffect, useCallback } from "react";
import {
  PrimaryButton,
  SecondaryLink,
  TertiaryButton,
} from "../../components/buttons/buttons";
import { useSearchParams } from "react-router-dom";
import SearchBar, { InputType } from "../../components/searchBar/searchBar";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import PaginatedItems from "../../components/pagination/paginate";
import SubscriptionsService from "../../services/subscriptions.service";
import { subscriptionType } from "../../models/subsrcription.model";
import Table, { actionsType, type disabledActionsType } from "../../components/table/table";
import { ToastType } from "../../parts/toast/toast";
import toastUtil from "../../utils/toast.util";
import { useDispatch, useSelector } from "react-redux";
import { closeToast } from "../../store/toast.slice";
import { useLocation, useNavigate } from "react-router-dom";
import DotsSpinner from "../../components/dotsSpinner/dotsSpinner";
import { collectionType } from "../../models/collection.model";
import { updateExpiringSubs } from "../../store/subscriptions.slice";
import AffectationService from "../../services/affectations.service";
import { AffectationModel, affectationType } from "../../models/affectation.model";
import subscriptionUtil, { type displayType } from "../../utils/subscription.util";
import type { Row } from "@tanstack/react-table";
import ExpiringSubscriptionsService from "../../services/expiringSubscription.service";

const Subscriptions: React.FC = () => {
  const [searchParams] = useSearchParams();
  const expiringSubs: subscriptionType[] = useSelector((state: any) => state.subscriptions?.expiringSubs);
  const mode = searchParams.get("mode");
  const [searching, setSearching] = useState<boolean>(false);
  const [reload, setReaload] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<subscriptionType[]>([]);
  const [uaiEtab, setUaiEtab] = useState<string>("");
  const [selectedUai, setSelectedUai] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [userRole, setUserRole] = useState<string>("");
  const [style, setStyle] = useState<string>("");
  const { currentItems, paginate } = PaginatedItems(10, mode === "expiring" ? expiringSubs : subscriptions);
  const subscriptionsService = new SubscriptionsService();
  const affectationService = new AffectationService();
  const expiringSubscriptionsService = new ExpiringSubscriptionsService();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = localStorage.getItem("currentUser");
  const [collection, setCollection] = useState<collectionType>();
  const location = useLocation();
  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    //user role is important to limit functionalities as multiple subscription
    if (user) {
      setUserRole(JSON.parse(user).role);
    }

  }, []);

  useEffect(() => {
    if (reload) {
      search();
      checkExpiringSubscriptions();
      setReaload(false);
    }
  }, [reload]);

  useEffect(() => {
    switch (mode) {
      case 'expiring':
        setTitle(subscriptionUtil.titleExpiring);
        if (expiringSubs?.length < 1) setMessage("Aucun abonnement n'expire dans les 60 jours prochains");
        break;
      case 'search':
        setTitle(subscriptionUtil.titleSearch);
        if (subscriptions.length < 1) setMessage("Veuillez spécifier un code UAI ou une collection");
        break;
    }

    checkExpiringSubscriptions();
    setCollection(undefined);
    hideAffectations();
    
  }, [mode, collection]);

  useEffect(() => {
    if (location.state?.collection) {
      onResetSearchUAI();
      let _collection: collectionType = location.state?.collection;
      if (_collection) {
        setCollection(_collection);
        setTitle("Abonnements de " + _collection.label);
        search(_collection);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (mode !== null) {
      search();
    }
  }, [selectedUai]);


  const search = useCallback(
    (_collection: any = null) => {
      if (!selectedUai && !collection) {
        switch (mode) {
          case 'expiring':
            setTitle(subscriptionUtil.titleExpiring);
            return checkExpiringSubscriptions();
          case 'search':
            setTitle(subscriptionUtil.titleSearch);
            return setSubscriptions([]);
        }
      }
      setSearching(true);

      const display: displayType = subscriptionUtil.getDisplayOnSearch(_collection, selectedUai, mode);

      setTitle(display.title);
      setStyle(display.style);
      subscriptionsService.getSubscriptions(display.filter).then((res) => {
        if (!res?.success) {
          const toastData: ToastType = {
            title: "Erreur de chargement",
            texts: [{ text: res?.data }],
            status: "error",
            position: "bottomRight",
            autoClose: false,
          };

          if (res && res.redirect !== null) {
            return navigate(res.redirect, { state: { error: res.data } });
          }
          return toastUtil.show(toastData, dispatch);
        }

        if (res.data.length < 1)
          setMessage(display.noResultMessage);

        mode === "expiring" ? dispatch(updateExpiringSubs({ expiringSubs: res.data })) : setSubscriptions(res.data);
        setSearching(false);
      });

    },
    [collection, selectedUai, mode]
  );

  const onUaiChange = (uai: string) => {
    setUaiEtab(uai);
    if (uai.length === 8) setSelectedUai(uai);
  };

  const onResetSearchUAI = () => {
    setUaiEtab("");
    setSelectedUai("");
  }

  const subscriptionSearchInputs: InputType[] = [
    { name: "UAI", type: "text", onChange: onUaiChange, onReset: onResetSearchUAI, value: uaiEtab },
  ];

  const updateSubscription = (subscription: subscriptionType) => {
    navigate("/newSubscription", { state: { update: subscription, from: mode === "expiring" ? "/subscriptions?mode=expiring" : "/searchSubscriptions?mode=search" } });
  };

  const duplicateSubscription = (subscription: subscriptionType) => {
    navigate("/newSubscription", { state: { duplicate: subscription, from: mode === "expiring" ? "/subscriptions?mode=expiring" : "/searchSubscriptions?mode=search" } });
  };

  const checkAffectation = (subscription: subscriptionType, nb: number) => {

    let id = subscription.idAbonnement;
    if (process.env.REACT_APP_ENV_VAR == "DEV" || process.env.REACT_APP_ENV_VAR == "local") { // Fake assignments (4th ligne : noAssignment)
      const affectation: AffectationModel = new AffectationModel({
        "cumulAffectationEleve": nb === 3 ? "0" : Math.floor(Math.random() * 301).toString(),
        "cumulAffectationEnseignant": nb === 3 ? "0" : Math.floor(Math.random() * 201).toString(),
        "cumulAffectationDocumentaliste": nb === 3 ? "0" : Math.floor(Math.random() * 51).toString(),
        "cumulAffectationAutrePersonnel": nb === 3 ? "0" : Math.floor(Math.random() * 31).toString()
      });

      Object.entries(affectation).forEach(([key, value]) => {
        if (key.startsWith("nb")) {
          addOrRemoveSpan(nb + "_" + key, value, nb === 3);
        }
      });
      return;
    }

    affectationService.checkAffectation(id).then((res: any) => {
      if (!res?.success) {
        const toastData: ToastType = {
          title: "Erreur de chargement",
          texts: [{ text: res?.data }],
          status: "error",
          position: "bottomRight",
          autoClose: false,
        };

        if (res && res.redirect !== null) {
          return navigate(res.redirect, { state: { error: res.data } });
        }
        return toastUtil.show(toastData, dispatch);
      }

      if (res.data) {
        const noAssignment: boolean = res.data.data.length < 1;
        try {
          const reponse = res.data.data[0] as affectationType;
          const affectation: AffectationModel = new AffectationModel(noAssignment ? undefined : reponse); // Show 0 if no assignment received

          Object.entries(affectation).forEach(([key, value]) => {
            if (key.startsWith("nb")) {
              addOrRemoveSpan(nb + "_" + key, value, noAssignment);
            }
          });
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const addOrRemoveSpan = (id: string, value: any, noAssignment: boolean) => {
    const tdElement = document.getElementById(id);
    if (!tdElement) return;

    const isExpanded = tdElement.dataset.expanded === "true";

    if (isExpanded) {
      tdElement.dataset.expanded = "false";
      tdElement.classList.remove("show-assignment");
    } else {
      tdElement.dataset.expanded = "true";
      tdElement.classList.add("show-assignment");

      let span = tdElement.querySelector(".dynamic-span") as HTMLSpanElement;
      if (!span) {
        span = document.createElement("span");
        span.className = "dynamic-span";
        tdElement.appendChild(span);
      }
      span.textContent = value;
      span.classList.toggle("redAssignment", noAssignment);
    }
  };

  const hideAffectations = () => {
    const cells = document.getElementsByClassName('show-assignment');
    Array.from(cells).forEach(cell => cell.classList.remove("show-assignment"));
  }

  const validateDeletion = (subscription: subscriptionType) => {
    const toastData: ToastType = {
      title: "Suppression",
      texts: [{ text: "Voulez-vous supprimer cette abonnement ?" }],
      status: "neutre",
      autoClose: false,
      blocking: true,
      position: "middle",
      buttons: [
        <TertiaryButton
          key="no"
          label="Non"
          onClick={() => dispatch(closeToast())}
          textClassname="text-sm px-6 py-2"
        />,
        <div onClick={() => dispatch(closeToast())}>
          <PrimaryButton
            key="yes"
            label="Oui"
            onClick={() => deleteSubscription(subscription.idAbonnement)}
            textClassname="text-sm"
          />
        </div>,
      ],
    };
    toastUtil.show(toastData, dispatch);
  };

  const deleteSubscription = (idAbonnement: string) => {
    subscriptionsService.deleteSubscription(idAbonnement).then((res: any) => {
      const toastData: ToastType = {
        title: res.success ? "Abonnement supprimé" : "Erreur",
        texts: [{ text: res.success ? "" : res.data }],
        status: res.success ? "success" : "error",
        position: "bottomRight",
        autoClose: res.success,
      };
      toastUtil.show(toastData, dispatch);

      setReaload(true);
    });
  };

  const checkExpiringSubscriptions = () => {

    setSubscriptions([]);
    const filter = subscriptionUtil.expiringFilter();
    let expSubs: subscriptionType[] = [];
    let DBexpSubs: subscriptionType[] = [];
    let alert: number = 0;

    function checkNextExpiringSubs(nSubs: subscriptionType[], eSubs: subscriptionType[]) {
      const idsSet = new Set(eSubs.map(sub => sub.idAbonnement));
      return {
        updatedNextSubs: nSubs.map(sub =>
          idsSet.has(sub.idAbonnement) ? { ...sub, style: "text-yellow-600" } : sub
        ),
        isExpiringWithContinuity: !nSubs.every(sub => idsSet.has(sub.idAbonnement))
      };
    }

    expiringSubscriptionsService.getExpiringSubscriptions().then((res) => {
      if (res.data) {
        DBexpSubs = res.data;
      }
    });

    subscriptionsService.getSubscriptions(filter).then(async (res) => {
      if (res?.success) {
        expiringSubscriptionsService.createExpiringSubscriptions(res.data);
        expSubs = [...res.data];
        await Promise.all(
          expSubs.map(async (sub: subscriptionType, i: number) => {
            const nextSubsFilter = subscriptionUtil.nexSubFilter(sub.uaiEtab, sub.idRessource, sub.finValidite);
            const nextRes = await subscriptionsService.getSubscriptions(nextSubsFilter);
            const nextExpSubs = checkNextExpiringSubs(nextRes.data, expSubs);
            if (nextRes.data.length > 0) {
              expSubs[i] = { ...sub, nextSubs: nextExpSubs.updatedNextSubs, style: nextExpSubs.isExpiringWithContinuity ? "" : "bg-yellow-50", isExpiringWithContinuity: nextExpSubs.isExpiringWithContinuity };
            } else {
              expSubs[i] = { ...sub, style: "bg-yellow-50", isExpiringWithContinuity: false };
            }
          })
        );
      }

      const expSubsUpdatedNotified = expSubs.map(expSub => {
        const match = DBexpSubs.find(DBexpSub => DBexpSub.idAbonnement === expSub.idAbonnement);
        return {
          ...expSub,
          notified: match ? match.notified : false,
          user: match ? match.user : '',
          updatedDate: match && match.updatedDate ? new Date(match.updatedDate).toLocaleDateString("fr-FR") : '',
        };
      });

      expSubsUpdatedNotified.forEach(sub => { if (!sub.notified && !sub.isExpiringWithContinuity) alert++ })


      dispatch(updateExpiringSubs({ expiringSubs: expSubsUpdatedNotified, alert }));
    });
  }

  const showNextSubs = ({ row }: { row: Row<subscriptionType> }) => {

    return (<React.Fragment>{row.original.nextSubs?.map((nextSub: any) => {
      return subscriptionUtil.nextSubRow(nextSub);
    })}
    </React.Fragment>)
  }

  const isGarNinjaSubscription = (row: Row<any>) => {
    return row.original.codeProjetRessource?.includes('TNE_');
  }

  const handleNotifiedChange = (item: subscriptionType) => {
    expiringSubscriptionsService.updateExpiringSubscription(item, true).then(() => setReaload(true))
  }

  const actions: actionsType = {
    update: updateSubscription,
    delete: validateDeletion,
    checkAff: checkAffectation
  };

  const expiringActions: actionsType = {
    duplicate: duplicateSubscription,
    delete: validateDeletion,
    checkAff: checkAffectation,
    notified: handleNotifiedChange
  };

  const disabledActions: disabledActionsType = {
    update: isGarNinjaSubscription,
    delete: isGarNinjaSubscription,
  };

  return (
    <div className="subscriptions flex flex-col">
      <div className="flex justify-between items-center">
        <h2 className="title">{title}</h2>
        <div className="button-group">
          {collection && mode !== "search" && (
            <SecondaryLink
              label="Annuler"
              to="/collections"
              btnClassname="backButton mr-4"
              textClassname="text-sm"
              icon={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
            />
          )}
        </div>
      </div>
      {mode == "search" &&
        <>
          <SearchBar
            inputs={subscriptionSearchInputs}
          />
        </>
      }
      {searching && <DotsSpinner className="centered" />}

      {currentItems.length > 0 &&
        <div className="mr-4 ml-4">
          <Table
            columns={subscriptionUtil.columns}
            data={currentItems}
            actions={mode === "expiring" ? expiringActions : actions}
            disabledActions={disabledActions}
            tableStyle={style}
            renderSubComponent={showNextSubs}
            getRowCanExpand={() => mode === "expiring"}
          />
          {paginate}
        </div>
      }

      {!searching && currentItems.length < 1 &&
        <div className="mt-8 p-3 mx-auto text-md">
          {message}
        </div>
      }


    </div>
  );
};

export default Subscriptions;
