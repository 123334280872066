import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import type { collectionType } from "../models/collection.model";
import { type ReactNode } from "react";
import type { ColumnDef } from "@tanstack/react-table";
import dateUtil from "./date.util";
import type { subscriptionType } from "../models/subsrcription.model";

export interface displayType {
  filter: {},
  title: string,
  style: string,
  noResultMessage: string
}

const subscriptionUtil = {

  titleExpiring: "Abonnements qui expirent d'ici 60 jours",
  titleExpired: "Abonnements expirés",
  titleSearch: "Rechercher un abonnement",
  titleCreate: "Création d'abonnement",
  titleUpdate: "Modification de l'abonnement",
  titleDuplicate: "Duplication de l'abonnement",
  messageNoResultUai: "Aucun abonnement sur cet établissement ou établissement inconnu",
  messageNoResultUaiCol: "Aucun abonnement sur cet établissement et cette collection",
  messageNoResultCol: "Aucun abonnement sur cette collection",
  messageNoResultExp: "Aucun abonnement expiré",
  messageNoSearchResultExp: "Aucun abonnement expiré pour cette recherche",

  columns: [
    {
      id: 'expander',
      header: () => null,
      cell: ({ row }) => {
        return row.getCanExpand() && row.original.nextSubs ? (
          <button
            {...{
              onClick: row.getToggleExpandedHandler(),
              style: { cursor: 'pointer' },
            }}
          >
            {row.getIsExpanded() ? <ChevronUpIcon className="w-8 h-8 text-indigo-600" /> : <ChevronDownIcon className="w-8 h-8 text-indigo-600" />}
          </button>
        ) : (
          ''
        )
      },
    },
    {
      accessorKey: "libelleRessource",
      header: "Collection",
      sortingFn: "alphanumeric",
    },
    {
      accessorKey: "uaiEtab",
      header: "UAI",
      sortingFn: "alphanumeric",
    },
    {
      accessorKey: "commentaireAbonnement",
      header: "Commentaire",
      sortingFn: "alphanumeric",
    },
    {
      accessorKey: "debutValidite",
      header: "Début",
      accessorFn: (row: { debutValidite: string | number | Date; }) =>
        `${new Date(row.debutValidite).toLocaleDateString("fr-FR")}`,
      sortingFn: "datetime",
    },
    {
      accessorKey: "finValidite",
      header: "Fin",
      accessorFn: (row: { finValidite: string | number | Date; }) =>
        `${new Date(row.finValidite).toLocaleDateString("fr-FR")}`,
      sortingFn: "datetime",
    },
    {
      accessorKey: "nbLicenceEleve",
      header: "#Elève",
      cell: (cell: { row: { original: any; }; }): ReactNode =>
        subscriptionUtil.displayNumberOrCheck(cell.row.original, "ELEVE", "nbLicenceEleve"),
      sortingFn: "alphanumeric",
    },
    {
      accessorKey: "nbLicenceEnseignant",
      header: "#Prof",
      cell: (cell: { row: { original: any; }; }): ReactNode =>
        subscriptionUtil.displayNumberOrCheck(
          cell.row.original,
          "ENSEIGNANT",
          "nbLicenceEnseignant"
        ),
      sortingFn: "alphanumeric",
    },
    {
      accessorKey: "nbLicenceDocumentalist",
      header: "#Doc.",
      cell: (cell: { row: { original: any; }; }): ReactNode =>
        subscriptionUtil.displayNumberOrCheck(
          cell.row.original,
          "DOCUMENTALISTE",
          "nbLicenceProfDoc"
        ),
      sortingFn: "alphanumeric",
    },
    {
      accessorKey: "nbLicenceAutrePersonnel",
      header: "#Autre",
      cell: (cell: { row: { original: any; }; }): ReactNode =>
        subscriptionUtil.displayNumberOrCheck(
          cell.row.original,
          "AUTRE PERSONNEL",
          "nbLicenceAutrePersonnel"
        ),
      sortingFn: "alphanumeric",
    },
    {
      accessorKey: "nbLicenceGlobale",
      header: "#Global",
      cell: (cell: { row: { original: any; }; }): ReactNode =>
        subscriptionUtil.shortUnlimited(cell.row.original["nbLicenceGlobale"]),
      sortingFn: "alphanumeric"
    },
    {
      accessorKey: "codeProjetRessource",
      header: "Projet",
      sortingFn: "alphanumeric",
    }
  ] as ColumnDef<any>[],

  expiringFilter: () => {
    const today = new Date();
    const futureDate = new Date();
    futureDate.setDate(today.getDate() + 60);

    let filter: any = {
      dateName: "finValidite",
      dateAvant: dateUtil.formatDateISO(futureDate),
      dateApres: dateUtil.formatDateISO(today)
    };
    return filter;
  },

  nexSubFilter: (uai: string = "", idRessource: string, finValidite: string) => {
    let filter: any = {
      uaiEtab: uai,
      idRessource: idRessource,
      dateName: "finValidite",
      dateApres: dateUtil.formatDateISO(new Date(finValidite))
    };
    return filter;
  },

  nextSubRow: (nextSub: subscriptionType) => {
    return <tr key={nextSub.idAbonnement} className={"border-b border-zinc-300 text-teal-700 " + nextSub.style}>
      <td>
        
      </td>
      <td>
        
      </td>
      <td>
        {nextSub.libelleRessource}
      </td>
      <td>
        {nextSub.uaiEtab}
      </td>
      <td>
        {nextSub?.commentaireAbonnement}
      </td>
      <td>
        {new Date(nextSub.debutValidite).toLocaleDateString("fr-FR")}
      </td>
      <td>
        {new Date(nextSub.finValidite).toLocaleDateString("fr-FR")}
      </td>
      <td>
        {subscriptionUtil.displayNumberOrCheck(nextSub, "ELEVE", "nbLicenceEleve")}
      </td>
      <td>
        {subscriptionUtil.displayNumberOrCheck(nextSub, "ENSEIGNANT", "nbLicenceEnseignant")}
      </td>
      <td>
        {subscriptionUtil.displayNumberOrCheck(nextSub, "DOCUMENTALISTE", "nbLicenceProfDoc")}
      </td>
      <td>
        {subscriptionUtil.displayNumberOrCheck(nextSub, "AUTRE PERSONNEL", "nbLicenceAutrePersonnel")}
      </td>
      <td>
        {nextSub.nbLicenceGlobale && subscriptionUtil.shortUnlimited(nextSub.nbLicenceGlobale.toString())}
      </td>
      <td>
        {nextSub.codeProjetRessource && nextSub.codeProjetRessource.toString()}
      </td>
      <td>

      </td>
    </tr>
  },

  getDisplayOnSearch: (
    selectedCol: collectionType | undefined,
    selectedUai: string,
    mode: string | null
  ): displayType => {
    let display: displayType = {
      filter: {},
      title: (mode === "expiring" ? subscriptionUtil.titleSearch + " en cours d'expiration" : subscriptionUtil.titleSearch) + " par ",
      style: '',
      noResultMessage: subscriptionUtil.messageNoResultUai
    }

    if (selectedCol?.idArk && selectedUai) {
      display.title += ` UAI : ${selectedUai} & collection : ${selectedCol?.label}`;
      display.style = "tableBoth";
      display.noResultMessage = subscriptionUtil.messageNoResultUaiCol;
      display.filter = { uaiEtab: selectedUai, idRessource: "ark:/86527/" + selectedCol.idArk };
    } else if (selectedCol?.idArk) {
      display.title += `collection : ${selectedCol?.label}`;
      display.style = "tableUai";
      display.noResultMessage = subscriptionUtil.messageNoResultCol;
      display.filter = { idRessource: "ark:/86527/" + selectedCol.idArk };
    } else if(selectedUai) {
      display.title += `UAI : ${selectedUai}`;
      display.style = "tableCollection",
      display.filter = { uaiEtab: selectedUai };
    }

    if (mode === "expiring")
      display.filter = { ...subscriptionUtil.expiringFilter(), ...display.filter }

    return display;
  },

  displayNumberOrCheck: (
    row: any, cible: string, nbLicence: string
  ) => {
    return row.nbLicenceGlobale ? (
      row.publicCible.includes(cible) ? (
        <CheckIcon className="h-5 w-5 mx-auto" />
      ) : (
        ""
      )
    ) : row[nbLicence] ? (
      subscriptionUtil.shortUnlimited(row[nbLicence])
    ) : (
      ""
    );
  },

  shortUnlimited: (licence: string) => {
    return licence === "ILLIMITE" ? (<span title={licence} className="text-2xl">∞</span>) : licence;
  },

  generateSubscriptionId: (
    col: collectionType,
    uai: string
  ) => {
    const date = new Date();
    const americanDate =
      date.getFullYear().toString() +
      (1 + date.getMonth()).toString() +
      date.getDate().toString();
    return "MASKOTT_" + americanDate + "_" + col.acronym + uai;
  },

  //accepted string:
  //"0600036N;070076N or '0600036N','070076N'"
  generateArray: (
    input: string
  ): string[] => {
    return input
      .split(/[,;]+/)               // Split the string by commas or semicolons
      .map(item => item.trim().replace(/^["']|["']$/g, '').toUpperCase())  // Trim whitespace, remove quotes, and convert to uppercase
      .filter(item => item !== '');  // Remove empty strings
  },

  sortCollectionsByMother: (a: collectionType, b: collectionType) => {
    if (a.mother < b.mother) {
      return 1;
    }
    if (a.mother > b.mother) {
      return -1;
    }
    return 0;
  },
};

export default subscriptionUtil;
