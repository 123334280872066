import "./navbar.scss";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import useDeviceDetection, { DEVICE_TYPE } from "../../hook/useDeviceDetection";
import { useEffect, useState } from "react";
import {
  UsersIcon,
  BookOpenIcon,
  BuildingOfficeIcon,
  CalendarDaysIcon,
  LockOpenIcon,
  MapPinIcon,
  QrCodeIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  ClockIcon,
  ArchiveBoxIcon
} from "@heroicons/react/24/outline";
import { ToastType } from "../toast/toast";
import toastUtil from "../../utils/toast.util";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryLink, TertiaryButton } from "../../components/buttons/buttons";
import { closeToast } from "../../store/toast.slice";
import AuthenticateService from "../../services/authenticate.service";
import type { subscriptionType } from "../../models/subsrcription.model";
import { USER_ROLE } from "../../constants";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<string>("");
  const userDevice = useDeviceDetection();
  const dispatch = useDispatch();
  const authService = new AuthenticateService();
  const user = localStorage.getItem("currentUser");
  const alert: number = useSelector((state: any) => state.subscriptions?.alert);

  useEffect(() => {
    if (user) {
      setUserRole(JSON.parse(user).role);
    }
  }, []);

  useEffect(() => {
    setIsMenuOpen(userDevice !== DEVICE_TYPE.MOBILE);
  }, [userDevice]);

  const validate = () => {
    const toastData: ToastType = {
      title: "Déconnexion",
      texts: [{ text: "Êtes vous sûr de vouloir vous déconnecter ?" }],
      status: "neutre",
      autoClose: false,
      blocking: true,
      position: "middle",
      buttons: [
        <TertiaryButton
          key="no"
          label="Non"
          onClick={() => dispatch(closeToast())}
          textClassname="text-sm px-6 py-2"
        />,
        <div
          onClick={() => {
            authService.logoutAuth();
            dispatch(closeToast());
          }}
        >
          <PrimaryLink
            key="yes"
            label="Oui"
            to="/login"
            textClassname="text-sm"
          />
        </div>,
      ],
    };
    toastUtil.show(toastData, dispatch);
  };


  return (
    <nav
      className={userDevice + " bg-indigo-950"}
      style={{ alignItems: "center" }}
    >
      <Link className="home" to="/">
        <img
          src="/logo_Maskott_blanc.png.png"
          alt="Logo"
          style={{ marginTop: "20px" }}
        />
      </Link>
      {userDevice === DEVICE_TYPE.MOBILE && (
        <label htmlFor="check">
          <input
            type="checkbox"
            id="check"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
          <span></span>
          <span></span>
          <span></span>
        </label>
      )}
      {isMenuOpen && (
        <ul>
          {(userRole !== USER_ROLE.VIEWER) && (
            <>
              <CustomLink
                label="Abonnements"
                icon={<CalendarDaysIcon className="h-5 w-5" aria-hidden="true" />}
              />
              <CustomLink
                to="/searchSubscriptions?mode=search"
                label="Recherche"
                classname="subMenu"
                icon={<MagnifyingGlassIcon className="h-4 w-4" aria-hidden="true" />}
              />
              <CustomLink
                to="/newSubscription"
                label="Création"
                classname="subMenu"
                icon={<PlusIcon className="h-4 w-4" aria-hidden="true" />}
              />
              <CustomLink
                to="/subscriptions?mode=expiring"
                label="En cours d'expiration"
                classname="subMenu"
                icon={<ClockIcon className="h-4 w-4" aria-hidden="true" />}
                notification={alert}
              />
              <CustomLink
                to="/expiredSubscriptions"
                label="Expiré"
                classname="subMenu"
                icon={<ArchiveBoxIcon className="h-4 w-4" aria-hidden="true" />}
              />
              <CustomLink
              to="/collections"
              label="Collections"
              icon={<BookOpenIcon className="h-5 w-5" aria-hidden="true" />}
            />
            </>
          )}
          {userRole === USER_ROLE.ADMIN && (
            <>
            <CustomLink
              to="/users"
              label="Utilisateurs"
              icon={<UsersIcon className="h-5 w-5" aria-hidden="true" />}
            />
            <CustomLink
              to="/enterprises"
              label="Enterprises"
              icon={<BuildingOfficeIcon className="h-5 w-5" aria-hidden="true" />}
            />
            <CustomLink
              to="/codes"
              label="Codes projet"
              icon={<QrCodeIcon className="h-5 w-5" aria-hidden="true" />}
            />
            </>
          )}

          {(userRole !== USER_ROLE.SUBSCRIBER) && (
            <CustomLink
              to="/maps"
              label="Maps"
              icon={<MapPinIcon className="h-5 w-5" aria-hidden="true" />}
            />
          )}

          <CustomLink
            to=""
            label="Déconnexion"
            classname="logout"
            icon={<LockOpenIcon className="h-5 w-5" aria-hidden="true" />}
            onClick={validate}
          />

        </ul>
      )}
    </nav>
  );
}

function CustomLink({
  to = "",
  label,
  classname = "",
  onClick = () => { },
  icon,
  notification,
}: {
  to?: string;
  label: string;
  classname?: string;
  onClick?: () => void;
  icon: any;
  notification?: number;
}) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li
      className={
        classname + (isActive ? " active" : "") + " hover:bg-indigo-800"
      }
      onClick={() => onClick()}
    >
      <span className="iconWrapper">{icon}</span>
      {to !== "" ? (
        <Link to={to}>{label}</Link>
      ) : (
        <p className="cursor-pointer">{label}</p>
      )}

      { notification != undefined && notification != 0 && <div className="notification bg-yellow-300 text-zinc-800">{notification.toString()}</div>}
    </li>
  );
}
