import { forwardRef, useCallback, useEffect, useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { fr } from "date-fns/locale/fr";
import "./subscriptions.scss";
import "react-datepicker/dist/react-datepicker.css";
import SubscriptionsService from "../../services/subscriptions.service";
import {
  PrimaryButton,
  SecondaryButton,
  SecondaryLink,
  ToggleButtons,
  type buttonType,
} from "../../components/buttons/buttons";
import InputText, { InputTextarea } from "../../components/inputText/inputText";
import { ChevronLeftIcon, ChevronRightIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import Checkbox from "../../components/checkbox/checkbox";
import { collectionType } from "../../models/collection.model";
import {
  SubscriptionModel,
  subscriptionType,
} from "../../models/subsrcription.model";
import { ToastTextType, ToastType } from "../../parts/toast/toast";
import toastUtil from "../../utils/toast.util";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addDays, addYears, subDays } from "date-fns";
import Select, { ItemType } from "../../components/select/select";
import { codeProjetRessourceType } from "../../models/codeProjetRessource.model";
import CodeProjetRessourcesService from "../../services/codeProjetRessources.service";

import { showContainerSpinner, hideContainerSpinner } from "../../store/spinner.slice";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import CollectionsService from "../../services/collections.service";
import subscriptionUtil from "../../utils/subscription.util";
import { USER_ROLE } from "../../constants";
import ExpiringSubscriptionsService from "../../services/expiringSubscription.service";

registerLocale("fr", fr);
setDefaultLocale("fr");

interface excludeDates {
  start: Date,
  end: Date,
}

const collectionsService = new CollectionsService();

const NewSubscription = () => {
  const [message, setMessage] = useState<string>("Veuillez entrer un code UAI");
  const [title, setTitle] = useState<string>(subscriptionUtil.titleCreate);
  const [example, setExample] = useState<string>("0600036N");
  const [collections, setCollections] = useState<collectionType[]>([]);
  const [codes, setCodes] = useState<ItemType[]>([{ id: "0", name: "Aucun" }]);
  const [selectedCollections, setSelectedCollections] = useState<
    collectionType[]
  >([]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const __endDate = new Date();
  __endDate.setMonth(7, 15);

  if (__endDate < startDate)
    __endDate.setFullYear(startDate.getFullYear() + 1);
  const [endDate, setEndDate] = useState<Date>(__endDate);
  const [excludeDate, setExcludeDates] = useState<excludeDates[]>([]);
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 5);
  const [commentaire, setCommentaire] = useState<string>("");
  const [uaiEtab, setUaiEtab] = useState<string>("");
  const [codeProjetRessource, setCodeProjetRessource] = useState<string>("");
  const [isMother, setIsMother] = useState<boolean>(false);
  const [studentLicenceNumber, setStudentLicenceNumber] = useState<number>(0);
  const [studentLicenceUnlimited, setStudentLicenceUnlimited] =
    useState<boolean>(false);
  const [teacherLicenceNumber, setTeacherLicenceNumber] = useState<number>(0);
  const [teacherLicenceUnlimited, setTeacherLicenceUnlimited] =
    useState<boolean>(false);
  const [otherLicenceNumber, setOtherLicenceNumber] = useState<number>(0);
  const [otherLicenceUnlimited, setOtherLicenceUnlimited] =
    useState<boolean>(false);
  const [documentalistLicenceNumber, setDocumentalistLicenceNumber] =
    useState<number>(0);
  const [documentalistLicenceUnlimited, setDocumentalistLicenceUnlimited] =
    useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [isMultiple, setIsMultiple] = useState<boolean>(false);

  const subscriptionsService = new SubscriptionsService();
  const codeProjetRessourcesService = new CodeProjetRessourcesService();
  const expiringSubscitpionsService = new ExpiringSubscriptionsService();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user: string = localStorage.getItem("currentUser") ?? "";
  const [userRole, setUserRole] = useState<string>("");
  const [subscriptions, setSubscriptions] = useState<subscriptionType[]>([]);
  const [similarSubscriptions, setSimilarSubscriptions] = useState<subscriptionType[]>([]);
  const [searching, setSearching] = useState<boolean>(false);

  const onSave = async () => {

    dispatch(showContainerSpinner());
    const subscriptions: Array<SubscriptionModel> = [];
    const arrayUaiEtab: Array<string> = subscriptionUtil.generateArray(uaiEtab);

    await selectedCollections.map((col, i) => {
      for (const uai of arrayUaiEtab) {
        const createSubscription = (start: Date, end: Date, suffix: string) => {
          const xmlValues: subscriptionType = {
            idUser: JSON.parse(user).email,
            idAbonnement: isUpdate
              ? location.state.update.idAbonnement
              : subscriptionUtil.generateSubscriptionId(col, uai) + suffix,
            commentaireAbonnement: commentaire !== "" ? commentaire : col.label,
            idDistributeurCom: "479875718_0000000000000000",
            idRessource: isUpdate
              ? location.state.update.idRessource
              : "ark:/86527/" + col.idArk,
            typeIdRessource: "ark",
            libelleRessource: isUpdate
              ? location.state.update.libelleRessource
              : col.label,
            debutValidite: start.toISOString(),
            finValidite: end.toISOString(),
            uaiEtab: uai,
            categorieAffectation: "transferable",
            typeAffectation: col.mother ? "ETABL" : "INDIV",
            nbLicenceGlobale: isUpdate
              ? location.state.update.nbLicenceGlobale
              : col.mother
                ? "ILLIMITE"
                : "",
            publicCibleStudent: studentLicenceNumber > 0 || studentLicenceUnlimited,
            publicCibleTeacher: teacherLicenceNumber > 0 || teacherLicenceUnlimited,
            publicCibleOther: otherLicenceNumber > 0 || otherLicenceUnlimited,
            publicCibledocumentaliste:
              documentalistLicenceNumber > 0 || documentalistLicenceUnlimited,
          };

          if (xmlValues.nbLicenceGlobale !== "ILLIMITE") {
            xmlValues.nbLicenceEnseignant = teacherLicenceUnlimited
              ? "ILLIMITE"
              : Number(teacherLicenceNumber);
            xmlValues.nbLicenceEleve = studentLicenceUnlimited
              ? "ILLIMITE"
              : Number(studentLicenceNumber);
            xmlValues.nbLicenceAutrePersonnel = otherLicenceUnlimited
              ? "ILLIMITE"
              : Number(otherLicenceNumber);
            xmlValues.nbLicenceProfDoc = documentalistLicenceUnlimited
              ? "ILLIMITE"
              : Number(documentalistLicenceNumber);
          }

          if (codeProjetRessource !== "0")
            xmlValues.codeProjetRessource = codeProjetRessource;

          if (isUpdate) delete xmlValues.uaiEtab;

          subscriptions.push(new SubscriptionModel(xmlValues));
        };

        const startDateValue = new Date(startDate as Date);
        const endDateValue = new Date(endDate as Date);
        endDateValue.setHours(0, 0, 0, 0)

        let currentStartDate = startDateValue;
        let currentEndDate = new Date(
          currentStartDate.getFullYear(),
          7,
          15
        );
        if (currentEndDate < currentStartDate)
          currentEndDate.setFullYear(currentEndDate.getFullYear() + 1);

        let splitting = true;

        while (splitting) {
          if (currentEndDate >= endDateValue) {
            currentEndDate = endDateValue;
            splitting = false;
          }

          const suffix =
            currentEndDate === endDateValue
              ? ""
              : `-${currentStartDate.getDay()}`;


          createSubscription(currentStartDate, currentEndDate, suffix);

          currentStartDate = new Date(currentEndDate);
          currentStartDate.setDate(currentEndDate.getDate() + 1);
          currentEndDate = new Date(
            currentStartDate.getFullYear() + 1, 7, 15
          );
        }
      }
    });

    if (subscriptions.length > 0) {
      if (subscriptions.length > 1) {
        createMany(subscriptions);
      } else createOrEditOne(subscriptions[0]);
    }
  };

  const createOrEditOne = async (subModel: SubscriptionModel) => {
    subscriptionsService[
      isUpdate ? "updateSubscription" : "createSubscription"
    ](subModel).then(
      (response: { success: boolean; data: string; details: string }) => {
        dispatch(hideContainerSpinner());
        const toastData: ToastType = {
          title: response.success ? "Abonnement enregistré" : "Erreur",
          texts: response.success
            ? []
            : [{ text: response.data, details: response.details }],
          status: response.success ? "success" : "error",
          position: "bottomRight",
          autoClose: response.success,
        };
        toastUtil.show(toastData, dispatch);
        if (response.success) {
          if (isUpdate || isDuplicate) {
            expiringSubscitpionsService.updateExpiringSubscription(subModel);
            navigate(location.state.from);
          } else {
            setCurrentPage(0);
          }
        }
      }
    );
  };

  const createMany = async (_subscriptions: Array<SubscriptionModel>) => {
    subscriptionsService.createSubscriptions(_subscriptions).then((res) => {
      dispatch(hideContainerSpinner());
      let messagesError: ToastTextType[] = [];
      if (!res.success) {
        res.details.map(
          (detail: { idAbonnement: string; error: string; data: string }) => {
            messagesError = [
              {
                text: detail.error + " for " + detail.idAbonnement,
                details: detail.data,
              },
              ...messagesError,
            ];
          }
        );
      }

      const toastData: ToastType = {
        title: res.success ? "Abonnements enregistrés" : "Erreur " + res.data,
        texts: messagesError,
        status: res.success ? "success" : "error",
        position: "bottomRight",
        autoClose: res.success,
      };
      toastUtil.show(toastData, dispatch);
      if (res.success)
        setCurrentPage(0);
    });
  };

  const setTextUai = (uaiEtab: string) => {
    setUaiEtab(uaiEtab);
    setSearching(false);
  }

  const checkIfExist = (collection: collectionType) => {

    //we need to allow correction of previous subscription creating new ones for admin (multi mode is allowed only for admin)
    if (isMultiple)
      return false

    const subsSortByStartDate = subscriptions.sort((a: subscriptionType, b: subscriptionType) => new Date(a.debutValidite).getTime() - new Date(b.debutValidite).getTime())

    let similarSubs: subscriptionType[] = [],
      newstartDate = startDate,
      newEndDate = endDate;

    subsSortByStartDate.forEach((subscription: subscriptionType) => {
      if (subscription.idRessource.includes(collection.idArk)) {
        similarSubs = [...similarSubs, subscription];
        setExcludeDates([{ start: new Date(subscription.debutValidite), end: new Date(subscription.finValidite) }, ...excludeDate]);

        if (newstartDate >= new Date(subscription.debutValidite) && newstartDate <= new Date(subscription.finValidite))
          newstartDate = addDays(new Date(subscription.finValidite), 1);


        if (endDate < new Date(subscription.finValidite))
          newEndDate = addYears(endDate, 1);

      }
    })

    setStartDate(newstartDate);
    setEndDate(newEndDate);
    setSimilarSubscriptions(similarSubs);

  }

  const checkIfMotherExist = (collection: collectionType) => {
    let value: boolean = false;
    subscriptions.forEach((subscription: subscriptionType) => {
      if (subscription.idRessource.includes(collection.parentIdArk)) {
        value = true;
        return;
      }
    })
    return value;
  }

  const loadSubscription = async (uaiEtab: string) => {
    const filter = { uaiEtab };
    setSearching(false);

    if (uaiEtab == "")
      return;

    subscriptionsService.getSubscriptions(filter).then((res) => {
      if (!res?.success) {
        const toastData: ToastType = {
          title: "Erreur de chargement",
          texts: [{ text: res?.data }],
          status: "error",
          position: "bottomRight",
          autoClose: false,
        };

        if (res.redirect !== null) {
          return navigate(res.redirect, { state: { error: res.data } });
        }
        return toastUtil.show(toastData, dispatch);
      }
      if (res.data.length < 1) {
        setMessage("Aucun abonnement sur cet établissement ou établissement inconnu");
        setSubscriptions([]);
      } else {
        setSubscriptions(res.data);
      }

      setSearching(true);
    });
  }

  const loadData = async () => {
    //recuperate the collections form the service
    collectionsService.getCollections(10000).then((res: any) => {

      if (!res.success) {
        const toastData: ToastType = {
          title: "Erreur de chargement",
          texts: [{ text: res?.data }],
          status: "error",
          position: "bottomRight",
          autoClose: false,
        };

        if (res.redirect !== null) {
          return navigate(res.redirect, { state: { error: res.data } });
        }
        return toastUtil.show(toastData, dispatch);
      }

      //sort the collection by mother first
      if (res.data?.docs && res.data?.docs.length > 0) {
        res.data.docs.sort(subscriptionUtil.sortCollectionsByMother);
        setCollections(res.data.docs);

        // recuperate the code projects
        codeProjetRessourcesService
          .getCodeProjetRessources(10000)
          .then((res) => {
            if (!res.success) {
              const toastData: ToastType = {
                title: "Erreur de chargement des codes ressource projet",
                texts: [{ text: res?.data }],
                status: "error",
                position: "bottomRight",
                autoClose: false,
              };
              return toastUtil.show(toastData, dispatch);
            }
            if (res.data?.docs) {
              res.data.docs.map((code: codeProjetRessourceType) =>
                setCodes((codes: ItemType[]) => [
                  ...codes,
                  { id: code.code, name: code.code },
                ])
              );
            }
          });
      }
    });
  };

  useEffect(() => {
    if (user) {
      setUserRole(JSON.parse(user).role);
    }

    loadData();
  }, []);

  useEffect(() => {
    if (selectedCollections.length > 0)
      setIsMother(selectedCollections[0].mother);
  }, [selectedCollections]);

  useEffect(() => {
    if (!isUpdate) {
      setStudentLicenceUnlimited(isMother);
      setTeacherLicenceUnlimited(isMother);
      setOtherLicenceUnlimited(isMother);
      setDocumentalistLicenceUnlimited(isMother);
    }
  }, [isMother]);

  useEffect(() => {
    setIsCompleted(uaiEtab !== "" && selectedCollections.length > 0);
  }, [uaiEtab, selectedCollections]);

  useEffect(() => {

    setSearching(isMultiple);
    setExample(isMultiple ? "0600036N;070076N or '0600036N','070076N'" : "0600036N")

  }, [isMultiple]);

  useEffect(() => {
    // UPDATE SUBSCRIPTION
    if (location.state?.update || location.state?.duplicate) {

      let dataToInsert: subscriptionType;
      if (location.state?.update) {
        setIsUpdate(true);
        setTitle(subscriptionUtil.titleUpdate);
        dataToInsert = location.state.update;
      } else {
        setIsDuplicate(true);
        setTitle(subscriptionUtil.titleDuplicate);
        dataToInsert = location.state.duplicate;
      }

      const _col = collections.find(
        (col: collectionType) =>
          "ark:/86527/" + col.idArk === dataToInsert.idRessource
      );

      if (_col !== undefined)
        setSelectedCollections([_col]);

      if (dataToInsert.uaiEtab)
        setUaiEtab(dataToInsert.uaiEtab);

      if (dataToInsert.codeProjetRessource)
        setCodeProjetRessource(dataToInsert.codeProjetRessource);

      setCommentaire(dataToInsert.commentaireAbonnement);

      const debutValidite = new Date(isDuplicate ? dataToInsert.finValidite : dataToInsert.debutValidite);
      const finValidite = new Date(dataToInsert.finValidite);

      if(isDuplicate){
        const _startDate = new Date(debutValidite.setDate(debutValidite.getDate() + 1));
        const _endDate = new Date(finValidite.getFullYear(), 7, 15);
        if (_endDate < startDate)
          _endDate.setFullYear(_endDate.getFullYear() + 1);
        setStartDate(_startDate);
        setEndDate(_endDate);
      }else{
        setStartDate(debutValidite);
        setEndDate(finValidite);
      }

      if (dataToInsert.nbLicenceGlobale === "ILLIMITE")
        setIsMother(true);

      if (dataToInsert.nbLicenceAutrePersonnel)
        dataToInsert.nbLicenceAutrePersonnel === "ILLIMITE"
          ? setOtherLicenceUnlimited(true)
          : setOtherLicenceNumber(
            dataToInsert.nbLicenceAutrePersonnel as number
          );

      if (dataToInsert.nbLicenceEleve)
        dataToInsert.nbLicenceEleve === "ILLIMITE"
          ? setStudentLicenceUnlimited(true)
          : setStudentLicenceNumber(
            dataToInsert.nbLicenceEleve as number
          );

      if (dataToInsert.nbLicenceEnseignant)
        dataToInsert.nbLicenceEnseignant === "ILLIMITE"
          ? setTeacherLicenceUnlimited(true)
          : setTeacherLicenceNumber(
            dataToInsert.nbLicenceEnseignant as number
          );

      if (dataToInsert.nbLicenceProfDoc)
        dataToInsert.nbLicenceProfDoc === "ILLIMITE"
          ? setDocumentalistLicenceUnlimited(true)
          : setDocumentalistLicenceNumber(
            dataToInsert.nbLicenceProfDoc as number
          );

      if (
        dataToInsert.publicCible &&
        dataToInsert.nbLicenceGlobale === "ILLIMITE"
      ) {
        setStudentLicenceUnlimited(
          dataToInsert.publicCible.includes("ELEVE")
        );
        setTeacherLicenceUnlimited(
          dataToInsert.publicCible.includes("ENSEIGNANT")
        );
        setDocumentalistLicenceUnlimited(
          dataToInsert.publicCible.includes("DOCUMENTALISTE")
        );
        setOtherLicenceUnlimited(
          dataToInsert.publicCible.includes("AUTRE PERSONNEL")
        );
      }
      setCurrentPage(1);
    }
  }, [location.state, collections]);

  const updateSelection = useCallback(
    (collection: collectionType) => {
      const check = checkIfMotherExist(collection);
      if (!isMultiple && !check && !collection.mother) {
        let sub = { 'label': '' };
        collections.forEach((col: collectionType) => {
          if (col.idArk.includes(collection.parentIdArk)) {
            sub = col;
          }
        });
        let motherTitle = "";
        if (sub && sub.label !== '') {
          motherTitle = sub.label;
        }
        const toastData: ToastType = {
          title: "Attention",
          texts: [{ text: "Vous devez d'abord créer un abonnement pour la collection mère " + motherTitle }],
          status: "error",
          position: "bottomRight",
          autoClose: true,
        };

        return toastUtil.show(toastData, dispatch);
      }

      checkIfExist(collection);

      if (
        selectedCollections.find(
          (col: collectionType, i: number) => col.idArk === collection.idArk
        )
      ) {
        selectedCollections.splice(selectedCollections.indexOf(collection), 1);
        setSelectedCollections([...selectedCollections]);
      } else {
        setSelectedCollections(
          selectedCollections.some((col: collectionType) => col.mother) ||
            collection.mother
            ? [collection]
            : [...selectedCollections, collection]
        );
      }
    },
    [selectedCollections, subscriptions, isMultiple]
  );

  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref) => (
    <InputText
      onClick={onClick}
      ref={ref}
      value={value}
      textStyle="text-center text-md"
      classname="w-28"
    />
  ));

  const ToggleButtonsProps: buttonType[] = [
    {
      label: "Unique",
      onClick: () => { setIsMultiple(false) },
      textClassname: "text-sm",
      btnClassname: isMultiple ? "" : "selected"
    },
    {
      label: "Multiple",
      onClick: () => { setIsMultiple(true) },
      textClassname: "text-sm",
      btnClassname: isMultiple ? "selected" : ""
    }
  ]


  return (
    <div>
      <header className="flex justify-between items-center pr-5">
        <h2 className="title">{title}</h2>
        <div className="button-group">
          {isUpdate && (
            <SecondaryLink
              label="Annuler"
              to={location.state.from}
              btnClassname="backButton"
              textClassname="text-sm"
              icon={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
            />)
          }
        </div>
      </header>
      {!isUpdate && !isDuplicate && userRole === USER_ROLE.ADMIN && <ToggleButtons buttons={ToggleButtonsProps}></ToggleButtons>}
      <form className="flex flex-col w-full max-w-3xl rounded-lg bg-white mb-6 p-6 mx-auto shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] new_sub">
        {currentPage === 0 && !isUpdate && (
          <section className="flex flex-col">
            {!isMultiple &&
              <div className="headerPage">
                <InputText
                  type="text"
                  label="UAI"
                  id="UAI"
                  classname="mb-3 md"
                  value={uaiEtab}
                  disabled={isUpdate}
                  onChange={(e: any) => setTextUai(e.target.value)}
                />
                <SecondaryButton
                  label="Rechercher"
                  onClick={() => loadSubscription(uaiEtab)}
                  textClassname="text-sm"
                  icon={<MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />}
                  disabled={uaiEtab.length == 0 || searching}
                />
              </div>
            }
            {!searching && uaiEtab.length == 0 &&
              <div className="mt-8 p-3 mx-auto text-md">
                {message}
              </div>
            }
            {searching &&
              <div className="subscriptionChecker">
                <div className="font-medium leading-6 text-gray-900 mb-0">
                  Selectionnez une ou plusieurs collection(s) :
                </div>
                <ul
                  role="list"
                  className="max-h-96 overflow-y-auto niceScroll mx-6 divide-y divide-blue-200 rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]"
                >
                  {collections.map((collection: collectionType) => {
                    return (
                      <li
                        className={
                          "w-full flex justify-between py-3 px-5 cursor-pointer " +
                          (collection.mother ? "text-indigo-700 " : "") +
                          (selectedCollections.includes(collection)
                            ? "bg-indigo-100"
                            : "") +
                          " hover:bg-indigo-50"
                        }
                        key={collection.idArk}
                        onClick={() => updateSelection(collection)}
                      >
                        {collection.label}
                        {!collection.mother && (checkIfMotherExist(collection) || isMultiple) && (
                          <Checkbox
                            id={collection.idArk}
                            label=""
                            onChange={() => { }}
                            checked={selectedCollections.includes(collection)}
                          />
                        )}
                      </li>
                    );
                  })}
                </ul>
                <div className="flex gap-4 justify-end items-center">
                  <SecondaryButton
                    label="Suivant"
                    onClick={() => setCurrentPage(1)}
                    textClassname="text-md mx-auto"
                    btnClassname="mt-8"
                    disabled={selectedCollections.length < 1}
                    icon={
                      <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    }
                  />
                </div>
              </div>}
          </section>
        )}
        {currentPage === 1 && (
          <section className="flex flex-col">
            <div className="flex flex-col leading-6 text-zinc-900 mb-8">
              <p className="text-md font-medium">
                Collection{selectedCollections.length > 1 ? "s " : " "}
                sélectionnée
                {selectedCollections.length > 1
                  ? "s : " + selectedCollections.length
                  : " :"}
              </p>
              <div className="flex flex-col font-normal">
                {selectedCollections.map((col, i) => (
                  <p
                    className={
                      (col.mother ? "text-indigo-600" : "text-zinc-950") +
                      " my-1"
                    }
                    key={col.idArk}
                  >
                    {col.label}
                  </p>
                ))}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="gap-y-2">
                <InputText
                  type="text"
                  label="UAI"
                  id="UAI"
                  classname="mb-3 md"
                  value={uaiEtab}
                  disabled={!isMultiple}
                  onChange={(e: any) => setUaiEtab(e.target.value)}
                />
                <div className="example-text">ex :</div>
                <div className="example-text">{example}</div>
                {!isMother && (
                  <Select
                    label="Code projet ressource"
                    items={codes}
                    sort="id"
                    selectedItem={codes.find((code) => code.id === "0")}
                    onChange={(value: ItemType) =>
                      setCodeProjetRessource(value.id)
                    }
                  />
                )}
              </div>
              <InputTextarea
                label="Commentaire"
                id="UAI"
                classname="md"
                row={4}
                value={commentaire}
                placeholder="Nom de la collection"
                onChange={(e: any) => setCommentaire(e.target.value)}
              />
            </div>

            {similarSubscriptions.length > 0 && !isMultiple &&
              <div className="flex flex-col leading-6 text-zinc-900 mt-6 bg-teal-50 rounded-lg p-4">
                <div className="flex items-center gap-2">
                  <InformationCircleIcon className="w-7 h-7" />
                  <p className="text-md font-medium">
                    Abonnement{similarSubscriptions.length > 1 ? "s " : " "}
                    similaire
                    {similarSubscriptions.length > 1 ? "s " : " "}
                    sur {similarSubscriptions.length > 1 ? " d'autres périodes de validité" : " une autre période de validité"} :
                  </p>
                </div>
                <div className="flex flex-col font-normal">
                  {similarSubscriptions.map((sub, i) => (
                    <p
                      className="text-teal-700 font-medium my-1"
                      key={sub.idAbonnement}
                    >
                      {new Date(sub.debutValidite).toLocaleDateString("fr-FR")} - {new Date(sub.finValidite).toLocaleDateString("fr-FR")}
                    </p>
                  ))}
                </div>
              </div>}

            <div className="datePickerWrapper w-full flex justify-between items-center my-6">
              <span className="block text-md font-medium leading-6 text-gray-900 w-6/12">
                Validité :
              </span>
              <div className="mb-[0.125rem] flex items-center min-h-[1.5rem] w-3/12">
                <label
                  className="block px-2 text-sm leading-6 text-gray-900"
                  htmlFor="radioDefault02"
                >
                  début
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  showIcon
                  selectsStart
                  toggleCalendarOnIconClick
                  selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  onChange={(date) => {
                    if (date) setStartDate(date);
                  }}
                  excludeDateIntervals={excludeDate}
                  customInput={<ExampleCustomInput />}
                />
              </div>
              <div className="mb-[0.125rem] flex items-center justify-end min-h-[1.5rem] w-3/12">
                <label
                  className="block px-2 text-sm leading-6 text-gray-900"
                  htmlFor="radioDefault02"
                >
                  fin
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  showIcon
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={addDays(startDate, 1)}
                  maxDate={addYears(startDate, 5)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  onChange={(date) => {
                    if (date) setEndDate(date);
                  }}
                  customInput={<ExampleCustomInput />}
                />
              </div>
            </div>

            {isMother ||
              location.state?.update?.nbLicenceGlobale === "ILLIMITE" ? (
              <>
                <div className="flex gap-1 justify-between items-center mb-6">
                  <span className="block text-md font-medium leading-6 text-gray-900 w-6/12">
                    Nombre de licence globale : ILLIMITE
                  </span>
                </div>
                <div>
                  <span className="block text-md font-medium leading-6 text-gray-900">
                    Publics cible :
                  </span>
                  <Checkbox
                    id="publicStudent"
                    label="Elèves"
                    onChange={(e) =>
                      setStudentLicenceUnlimited(e.target.checked)
                    }
                    defaultChecked
                    checked={studentLicenceUnlimited}
                    classname="pl-10"
                  />
                  <Checkbox
                    id="publicTeacher"
                    label="Enseignants"
                    onChange={(e) =>
                      setTeacherLicenceUnlimited(e.target.checked)
                    }
                    defaultChecked
                    checked={teacherLicenceUnlimited}
                    classname="pl-10"
                  />
                  <Checkbox
                    id="publicOther"
                    label="Autre personnel"
                    onChange={(e) => setOtherLicenceUnlimited(e.target.checked)}
                    defaultChecked
                    checked={otherLicenceUnlimited}
                    classname="pl-10"
                  />
                  <Checkbox
                    id="publicDocumentalist"
                    label="Documentaliste"
                    onChange={(e) =>
                      setDocumentalistLicenceUnlimited(e.target.checked)
                    }
                    defaultChecked
                    checked={documentalistLicenceUnlimited}
                    classname="pl-10"
                  />
                </div>
              </>
            ) : (
              <>
                <span className="block text-md font-medium leading-6 text-gray-900">
                  Publics cible :
                </span>
                <div className="flex gap-1 justify-between items-center mb-2">
                  <p
                    className={
                      (studentLicenceNumber > 0 || studentLicenceUnlimited
                        ? "font-semibold text-indigo-700"
                        : "") + " w-6/12"
                    }
                  >
                    Elèves :
                  </p>
                  <Checkbox
                    id="studentUnlimited"
                    label="Illimité"
                    checked={studentLicenceUnlimited}
                    onChange={(e) =>
                      setStudentLicenceUnlimited(e.target.checked)
                    }
                  />
                  <span className="w-2/12 text-center">ou</span>
                  <InputText
                    type="number"
                    label=""
                    id="studentNumber"
                    placeholder="Nombre"
                    classname="w-2/12 ml-3"
                    disabled={studentLicenceUnlimited}
                    value={studentLicenceNumber.toString()}
                    onChange={(e: any) =>
                      setStudentLicenceNumber(e.target.value)
                    }
                  />
                </div>
                <div className="flex gap-1 justify-between items-center mb-2">
                  <p
                    className={
                      (teacherLicenceNumber > 0 || teacherLicenceUnlimited
                        ? "font-semibold text-indigo-700"
                        : "") + " w-6/12 "
                    }
                  >
                    Enseignants :
                  </p>
                  <Checkbox
                    id="teacherUnlimited"
                    label="Illimité"
                    checked={teacherLicenceUnlimited}
                    onChange={(e) =>
                      setTeacherLicenceUnlimited(e.target.checked)
                    }
                  />
                  <span className="w-2/12 text-center">ou</span>
                  <InputText
                    type="number"
                    label=""
                    id="teacherNumber"
                    placeholder="Nombre"
                    classname="w-2/12 ml-3"
                    value={teacherLicenceNumber.toString()}
                    disabled={teacherLicenceUnlimited}
                    onChange={(e: any) =>
                      setTeacherLicenceNumber(e.target.value)
                    }
                  />
                </div>
                <div className="flex gap-1 justify-between items-center mb-2">
                  <p
                    className={
                      (otherLicenceNumber > 0 || otherLicenceUnlimited
                        ? "font-semibold text-indigo-700"
                        : "") + " w-6/12 "
                    }
                  >
                    Autre personnel :
                  </p>
                  <Checkbox
                    id="otherUnlimited"
                    label="Illimité"
                    checked={otherLicenceUnlimited}
                    onChange={(e) => setOtherLicenceUnlimited(e.target.checked)}
                  />
                  <span className="w-2/12 text-center">ou</span>
                  <InputText
                    type="number"
                    label=""
                    id="otherNumber"
                    placeholder="Nombre"
                    classname="w-2/12 ml-3"
                    value={otherLicenceNumber.toString()}
                    disabled={otherLicenceUnlimited}
                    onChange={(e: any) => setOtherLicenceNumber(e.target.value)}
                  />
                </div>
                <div className="flex gap-1 justify-between items-center mb-2">
                  <p
                    className={
                      (documentalistLicenceNumber > 0 ||
                        documentalistLicenceUnlimited
                        ? "font-semibold text-indigo-700"
                        : "") + " w-6/12 "
                    }
                  >
                    Documentaliste :
                  </p>
                  <Checkbox
                    id="documentalistUnlimited"
                    label="Illimité"
                    checked={documentalistLicenceUnlimited}
                    onChange={(e) =>
                      setDocumentalistLicenceUnlimited(e.target.checked)
                    }
                  />
                  <span className="w-2/12 text-center">ou</span>
                  <InputText
                    type="number"
                    label=""
                    id="documentalistNumber"
                    placeholder="Nombre"
                    classname="w-2/12 ml-3"
                    value={documentalistLicenceNumber.toString()}
                    disabled={documentalistLicenceUnlimited}
                    onChange={(e: any) =>
                      setDocumentalistLicenceNumber(e.target.value)
                    }
                  />
                </div>
              </>
            )}
            <div className="flex gap-4 justify-end items-center">
              <SecondaryButton
                label={isUpdate || isDuplicate ? "Annuler" : "Retour"}
                onClick={() =>
                  isUpdate || isDuplicate ? navigate(location.state.from) : setCurrentPage(0)
                }
                textClassname="text-md mx-auto"
                btnClassname="mt-8"
                icon={
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                }
              />
              <PrimaryButton
                label="Enregistrer"
                onClick={onSave}
                textClassname="text-md mx-auto"
                btnClassname="mt-8"
                disabled={!isCompleted}
              />
            </div>
          </section>
        )}
      </form>
    </div>
  );
};

export default NewSubscription;
