import { CollectionModel, collectionType } from "../models/collection.model";
import { StatusCodes } from "http-status-codes";
import { BaseService } from "./base.service";
import { ReturnPagination } from "../models/return.model";
import { PaginationModel } from "../models/pagination.model";

export default class AffectationService extends BaseService {
  constructor() {
    super();
  }
  
  checkAffectation = async (
    id: string
  ) => {
    return await this.axios
      .get(`${this.apiUrl}/api/affectation`, {
        headers: this.getHeader(),
        params: {
          idAbonnement: id
        },
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if (
          [
            StatusCodes.CREATED,
            StatusCodes.NO_CONTENT,
            StatusCodes.ACCEPTED,
            StatusCodes.OK,
          ].includes(res.status)
        ) {
          return {
            data: res.data,
            success: true,
          };
        }
       
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response.status +
            " : " +
            error.response.data?.error,
          success: false,
          redirect: this.checkError(error.response.status),
        };
      });
  };
}
